export function toHex(uint8Array: Uint8Array): string {
  return Array.from(uint8Array).reduce((hex, byte) => {
    return hex + byte.toString(16).padStart(2, '0')
  }, '')
}
export function fromHex(hex: string): Uint8Array {
  if (hex.length % 2 !== 0) {
    throw new Error('Invalid hex string')
  }
  const bytes = new Uint8Array(hex.length / 2)
  for (let i = 0; i < hex.length; i += 2) {
    bytes[i / 2] = parseInt(hex.substring(i, i + 2), 16)
  }
  return bytes
}
