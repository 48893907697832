// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "auth_service.proto" (package "auth_service", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message auth_service.Empty
 */
export interface Empty {
}
// GetAccountInfo

/**
 * @generated from protobuf message auth_service.AccountInfoRequest
 */
export interface AccountInfoRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
}
/**
 * @generated from protobuf message auth_service.AccountInfoResponse
 */
export interface AccountInfoResponse {
    /**
     * @generated from protobuf field: bool hasAccount = 1;
     */
    hasAccount: boolean;
    /**
     * @generated from protobuf field: bool hasMobileAuth = 2;
     */
    hasMobileAuth: boolean;
}
// SendEmailPasscode

/**
 * @generated from protobuf message auth_service.SendEmailPasscodeRequest
 */
export interface SendEmailPasscodeRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
}
// ValidatePasscode

/**
 * @generated from protobuf message auth_service.ValidatePasscodeRequest
 */
export interface ValidatePasscodeRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
    /**
     * @generated from protobuf field: string passcode = 2;
     */
    passcode: string;
    /**
     * @generated from protobuf field: bool isMobileAuth = 3;
     */
    isMobileAuth: boolean;
}
/**
 * @generated from protobuf message auth_service.CheckLoginAvailabilityRequest
 */
export interface CheckLoginAvailabilityRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
}
/**
 * @generated from protobuf message auth_service.LoginAvailability
 */
export interface LoginAvailability {
    /**
     * @generated from protobuf field: bool isLoginAllowed = 1;
     */
    isLoginAllowed: boolean;
    /**
     * @generated from protobuf field: int64 timeToWait = 2;
     */
    timeToWait: bigint;
}
/**
 * @generated from protobuf message auth_service.AuthTokens
 */
export interface AuthTokens {
    /**
     * @generated from protobuf field: string accessToken = 1;
     */
    accessToken: string;
    /**
     * @generated from protobuf field: string refreshToken = 2;
     */
    refreshToken: string;
}
// GetSecretQR

/**
 * @generated from protobuf message auth_service.GetNewSecretQRRequest
 */
export interface GetNewSecretQRRequest {
    /**
     * @generated from protobuf field: string passcode = 1;
     */
    passcode: string;
    /**
     * @generated from protobuf field: bool isMobileAuth = 2;
     */
    isMobileAuth: boolean;
}
/**
 * @generated from protobuf message auth_service.GetNewSecretQRResponse
 */
export interface GetNewSecretQRResponse {
    /**
     * @generated from protobuf field: bytes qrImage = 1;
     */
    qrImage: Uint8Array; // base64 encoded png
}
// JWT

/**
 * @generated from protobuf message auth_service.RefreshJWTRequest
 */
export interface RefreshJWTRequest {
    /**
     * @generated from protobuf field: string refreshToken = 1;
     */
    refreshToken: string;
}
/**
 * @generated from protobuf message auth_service.RefreshJWTResponse
 */
export interface RefreshJWTResponse {
    /**
     * @generated from protobuf field: string accessToken = 1;
     */
    accessToken: string;
}
// Google Auth

/**
 * @generated from protobuf message auth_service.GoogleAuthRequest
 */
export interface GoogleAuthRequest {
    /**
     * @generated from protobuf field: string idToken = 1;
     */
    idToken: string;
}
/**
 * @generated from protobuf message auth_service.UserId
 */
export interface UserId {
    /**
     * @generated from protobuf field: int32 userId = 1;
     */
    userId: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class Empty$Type extends MessageType<Empty> {
    constructor() {
        super("auth_service.Empty", []);
    }
    create(value?: PartialMessage<Empty>): Empty {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<Empty>(this, message, value);
        return message;
    }
    internalBinaryRead(target?: Empty): Empty {
        return target ?? this.create();
    }
    internalBinaryWrite(message: Empty, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message auth_service.Empty
 */
export const Empty = new Empty$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccountInfoRequest$Type extends MessageType<AccountInfoRequest> {
    constructor() {
        super("auth_service.AccountInfoRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AccountInfoRequest>): AccountInfoRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.email = "";
        if (value !== undefined)
            reflectionMergePartial<AccountInfoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AccountInfoRequest): AccountInfoRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AccountInfoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message auth_service.AccountInfoRequest
 */
export const AccountInfoRequest = new AccountInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccountInfoResponse$Type extends MessageType<AccountInfoResponse> {
    constructor() {
        super("auth_service.AccountInfoResponse", [
            { no: 1, name: "hasAccount", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "hasMobileAuth", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<AccountInfoResponse>): AccountInfoResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.hasAccount = false;
        message.hasMobileAuth = false;
        if (value !== undefined)
            reflectionMergePartial<AccountInfoResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AccountInfoResponse): AccountInfoResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool hasAccount */ 1:
                    message.hasAccount = reader.bool();
                    break;
                case /* bool hasMobileAuth */ 2:
                    message.hasMobileAuth = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AccountInfoResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool hasAccount = 1; */
        if (message.hasAccount !== false)
            writer.tag(1, WireType.Varint).bool(message.hasAccount);
        /* bool hasMobileAuth = 2; */
        if (message.hasMobileAuth !== false)
            writer.tag(2, WireType.Varint).bool(message.hasMobileAuth);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message auth_service.AccountInfoResponse
 */
export const AccountInfoResponse = new AccountInfoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendEmailPasscodeRequest$Type extends MessageType<SendEmailPasscodeRequest> {
    constructor() {
        super("auth_service.SendEmailPasscodeRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SendEmailPasscodeRequest>): SendEmailPasscodeRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.email = "";
        if (value !== undefined)
            reflectionMergePartial<SendEmailPasscodeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendEmailPasscodeRequest): SendEmailPasscodeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendEmailPasscodeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message auth_service.SendEmailPasscodeRequest
 */
export const SendEmailPasscodeRequest = new SendEmailPasscodeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValidatePasscodeRequest$Type extends MessageType<ValidatePasscodeRequest> {
    constructor() {
        super("auth_service.ValidatePasscodeRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "passcode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "isMobileAuth", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ValidatePasscodeRequest>): ValidatePasscodeRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.email = "";
        message.passcode = "";
        message.isMobileAuth = false;
        if (value !== undefined)
            reflectionMergePartial<ValidatePasscodeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ValidatePasscodeRequest): ValidatePasscodeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* string passcode */ 2:
                    message.passcode = reader.string();
                    break;
                case /* bool isMobileAuth */ 3:
                    message.isMobileAuth = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ValidatePasscodeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* string passcode = 2; */
        if (message.passcode !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.passcode);
        /* bool isMobileAuth = 3; */
        if (message.isMobileAuth !== false)
            writer.tag(3, WireType.Varint).bool(message.isMobileAuth);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message auth_service.ValidatePasscodeRequest
 */
export const ValidatePasscodeRequest = new ValidatePasscodeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckLoginAvailabilityRequest$Type extends MessageType<CheckLoginAvailabilityRequest> {
    constructor() {
        super("auth_service.CheckLoginAvailabilityRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CheckLoginAvailabilityRequest>): CheckLoginAvailabilityRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.email = "";
        if (value !== undefined)
            reflectionMergePartial<CheckLoginAvailabilityRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckLoginAvailabilityRequest): CheckLoginAvailabilityRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckLoginAvailabilityRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message auth_service.CheckLoginAvailabilityRequest
 */
export const CheckLoginAvailabilityRequest = new CheckLoginAvailabilityRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginAvailability$Type extends MessageType<LoginAvailability> {
    constructor() {
        super("auth_service.LoginAvailability", [
            { no: 1, name: "isLoginAllowed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "timeToWait", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<LoginAvailability>): LoginAvailability {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.isLoginAllowed = false;
        message.timeToWait = 0n;
        if (value !== undefined)
            reflectionMergePartial<LoginAvailability>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoginAvailability): LoginAvailability {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool isLoginAllowed */ 1:
                    message.isLoginAllowed = reader.bool();
                    break;
                case /* int64 timeToWait */ 2:
                    message.timeToWait = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoginAvailability, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool isLoginAllowed = 1; */
        if (message.isLoginAllowed !== false)
            writer.tag(1, WireType.Varint).bool(message.isLoginAllowed);
        /* int64 timeToWait = 2; */
        if (message.timeToWait !== 0n)
            writer.tag(2, WireType.Varint).int64(message.timeToWait);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message auth_service.LoginAvailability
 */
export const LoginAvailability = new LoginAvailability$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthTokens$Type extends MessageType<AuthTokens> {
    constructor() {
        super("auth_service.AuthTokens", [
            { no: 1, name: "accessToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "refreshToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthTokens>): AuthTokens {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accessToken = "";
        message.refreshToken = "";
        if (value !== undefined)
            reflectionMergePartial<AuthTokens>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthTokens): AuthTokens {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accessToken */ 1:
                    message.accessToken = reader.string();
                    break;
                case /* string refreshToken */ 2:
                    message.refreshToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthTokens, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accessToken = 1; */
        if (message.accessToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accessToken);
        /* string refreshToken = 2; */
        if (message.refreshToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.refreshToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message auth_service.AuthTokens
 */
export const AuthTokens = new AuthTokens$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNewSecretQRRequest$Type extends MessageType<GetNewSecretQRRequest> {
    constructor() {
        super("auth_service.GetNewSecretQRRequest", [
            { no: 1, name: "passcode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "isMobileAuth", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetNewSecretQRRequest>): GetNewSecretQRRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.passcode = "";
        message.isMobileAuth = false;
        if (value !== undefined)
            reflectionMergePartial<GetNewSecretQRRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNewSecretQRRequest): GetNewSecretQRRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string passcode */ 1:
                    message.passcode = reader.string();
                    break;
                case /* bool isMobileAuth */ 2:
                    message.isMobileAuth = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNewSecretQRRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string passcode = 1; */
        if (message.passcode !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.passcode);
        /* bool isMobileAuth = 2; */
        if (message.isMobileAuth !== false)
            writer.tag(2, WireType.Varint).bool(message.isMobileAuth);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message auth_service.GetNewSecretQRRequest
 */
export const GetNewSecretQRRequest = new GetNewSecretQRRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNewSecretQRResponse$Type extends MessageType<GetNewSecretQRResponse> {
    constructor() {
        super("auth_service.GetNewSecretQRResponse", [
            { no: 1, name: "qrImage", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetNewSecretQRResponse>): GetNewSecretQRResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.qrImage = new Uint8Array(0);
        if (value !== undefined)
            reflectionMergePartial<GetNewSecretQRResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNewSecretQRResponse): GetNewSecretQRResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes qrImage */ 1:
                    message.qrImage = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNewSecretQRResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes qrImage = 1; */
        if (message.qrImage.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.qrImage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message auth_service.GetNewSecretQRResponse
 */
export const GetNewSecretQRResponse = new GetNewSecretQRResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefreshJWTRequest$Type extends MessageType<RefreshJWTRequest> {
    constructor() {
        super("auth_service.RefreshJWTRequest", [
            { no: 1, name: "refreshToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RefreshJWTRequest>): RefreshJWTRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.refreshToken = "";
        if (value !== undefined)
            reflectionMergePartial<RefreshJWTRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefreshJWTRequest): RefreshJWTRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string refreshToken */ 1:
                    message.refreshToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RefreshJWTRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string refreshToken = 1; */
        if (message.refreshToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.refreshToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message auth_service.RefreshJWTRequest
 */
export const RefreshJWTRequest = new RefreshJWTRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefreshJWTResponse$Type extends MessageType<RefreshJWTResponse> {
    constructor() {
        super("auth_service.RefreshJWTResponse", [
            { no: 1, name: "accessToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RefreshJWTResponse>): RefreshJWTResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accessToken = "";
        if (value !== undefined)
            reflectionMergePartial<RefreshJWTResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefreshJWTResponse): RefreshJWTResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accessToken */ 1:
                    message.accessToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RefreshJWTResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accessToken = 1; */
        if (message.accessToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accessToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message auth_service.RefreshJWTResponse
 */
export const RefreshJWTResponse = new RefreshJWTResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoogleAuthRequest$Type extends MessageType<GoogleAuthRequest> {
    constructor() {
        super("auth_service.GoogleAuthRequest", [
            { no: 1, name: "idToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GoogleAuthRequest>): GoogleAuthRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.idToken = "";
        if (value !== undefined)
            reflectionMergePartial<GoogleAuthRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GoogleAuthRequest): GoogleAuthRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string idToken */ 1:
                    message.idToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GoogleAuthRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string idToken = 1; */
        if (message.idToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message auth_service.GoogleAuthRequest
 */
export const GoogleAuthRequest = new GoogleAuthRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserId$Type extends MessageType<UserId> {
    constructor() {
        super("auth_service.UserId", [
            { no: 1, name: "userId", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<UserId>): UserId {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userId = 0;
        if (value !== undefined)
            reflectionMergePartial<UserId>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserId): UserId {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 userId */ 1:
                    message.userId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserId, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 userId = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message auth_service.UserId
 */
export const UserId = new UserId$Type();
/**
 * @generated ServiceType for protobuf service auth_service.Auth
 */
export const Auth = new ServiceType("auth_service.Auth", [
    { name: "GetAccountInfo", options: {}, I: AccountInfoRequest, O: AccountInfoResponse },
    { name: "SendEmailPasscode", options: {}, I: SendEmailPasscodeRequest, O: Empty },
    { name: "ValidatePasscode", options: {}, I: ValidatePasscodeRequest, O: AuthTokens },
    { name: "CheckLoginAvailability", options: {}, I: CheckLoginAvailabilityRequest, O: LoginAvailability },
    { name: "GetNewSecretQR", options: {}, I: GetNewSecretQRRequest, O: GetNewSecretQRResponse },
    { name: "RefreshJWT", options: {}, I: RefreshJWTRequest, O: RefreshJWTResponse },
    { name: "AuthWithGoogle", options: {}, I: GoogleAuthRequest, O: AuthTokens }
]);
/**
 * @generated ServiceType for protobuf service auth_service.AuthPrivate
 */
export const AuthPrivate = new ServiceType("auth_service.AuthPrivate", [
    { name: "CreateAccount", options: {}, I: AccountInfoRequest, O: UserId },
    { name: "RemoveUserKeyIfExists", options: {}, I: UserId, O: Empty }
]);
