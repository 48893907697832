export default [
  {
    path: 'test',
    children: [
      {
        path: ':submissionId',
        component: () => import('@/views/test/Test.vue'),
        name: 'Test',
      },
      {
        path: ':submissionId/result',
        component: () => import('@/views/test/TestResult.vue'),
        name: 'TestResult',
      },
    ],
  },
]
