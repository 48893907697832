import { defineStore } from 'pinia'

interface State {
  fetchInterval: NodeJS.Timeout | null
}

export const useNotificationsStore = defineStore('notifications', {
  state: (): State => ({
    fetchInterval: null,
  }),
  actions: {
    setFetchInterval(interval: NodeJS.Timeout) {
      this.fetchInterval = interval
    },
    clearFetchInterval() {
      if (this.fetchInterval) clearInterval(this.fetchInterval)
    },
  },
})
