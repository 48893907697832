import { createApp, defineComponent, h, onMounted, onUpdated, ref } from 'vue'
import { createPinia } from 'pinia'
import GoogleSignInPlugin from 'vue3-google-signin'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import '@/assets/scss/main.scss'
import '@/assets/scss/variables.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import App from './App.vue'
import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'
import 'highlight.js/styles/github-dark.css'
import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'
import 'katex/dist/katex.min.css'

const pinia = createPinia()
const app = createApp(App)

declare global {
  interface Window {
    MathJax: any
  }
}

const MathJax = window.MathJax || null
MathJax.Hub.Config({
  TeX: {
    extensions: ['AMSmath.js', 'AMScd.js', 'noErrors.js', 'noUndefined.js', 'action.js', 'cancel.js', 'HTML.js'],
    equationNumbers: { autoNumber: 'AMS' },
    Macros: {
      RR: '{\\bf R}',
      bold: ['{\\bf #1}', 1],
    },
    noErrors: { disabled: true },
    noUndefined: {
      attributes: {
        mathcolor: 'red',
        mathbackground: '#FFEEEE',
        mathsize: '90%',
      },
    },
    processRefs: true,
    processEnvironments: true,
  },

  tex2jax: {
    inlineMath: [
      ['$$$', '$$$'],
      ['\\(', '\\)'],
    ],
    displayMath: [
      ['$$$$$$', '$$$$$$'],
      ['[', ']'],
    ],
    processRefs: true,
    processEnvironments: true,
  },
  jax: ['input/TeX', 'output/HTML-CSS'],
})

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.component(
  'mathjax-span',
  defineComponent({
    setup(_, { slots }) {
      const spanRef = ref<HTMLElement | null>(null)

      onMounted(() => {
        MathJax?.Hub.Queue(['Typeset', MathJax.Hub, spanRef.value])
      })

      onUpdated(() => {
        MathJax?.Hub.Queue(['Typeset', MathJax.Hub, spanRef.value])
      })

      return () => {
        const defaultSlot = slots.default ? slots.default() : []
        const textContent = defaultSlot[0]?.children || ''
        return h('span', { ref: spanRef, innerHTML: textContent })
      }
    },
  }),
)

app.use(GoogleSignInPlugin, {
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
})

app.use(VueMonacoEditorPlugin, {
  paths: {
    vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.52.0/min/vs',
  },
})

app.use(ElementPlus)
app.use(pinia)
app.use(hljsVuePlugin)

app.use(router).mount('#app')
