// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "file_manager_service.proto" (package "file_manager_service", syntax proto3)
// tslint:disable
import { FileManagerPublic } from "./file_manager_service";
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { FileManagerPrivate } from "./file_manager_service";
import type { Empty } from "./file_manager_service";
import type { UserId } from "./file_manager_service";
import type { FileInfo } from "./file_manager_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { DownloadFileStream } from "./file_manager_service";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { HashSum } from "./file_manager_service";
import type { UploadFileStream } from "./file_manager_service";
import type { ClientStreamingCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * ———————————————————— File Manager Private Service ————————————————————
 *
 * @generated from protobuf service file_manager_service.FileManagerPrivate
 */
export interface IFileManagerPrivateClient {
    /**
     * @generated from protobuf rpc: UploadFile(stream file_manager_service.UploadFileStream) returns (file_manager_service.HashSum);
     */
    uploadFile(options?: RpcOptions): ClientStreamingCall<UploadFileStream, HashSum>;
    /**
     * @generated from protobuf rpc: DownloadFile(file_manager_service.HashSum) returns (stream file_manager_service.DownloadFileStream);
     */
    downloadFile(input: HashSum, options?: RpcOptions): ServerStreamingCall<HashSum, DownloadFileStream>;
    /**
     * @generated from protobuf rpc: GetFileInfo(file_manager_service.HashSum) returns (file_manager_service.FileInfo);
     */
    getFileInfo(input: HashSum, options?: RpcOptions): UnaryCall<HashSum, FileInfo>;
    /**
     * @generated from protobuf rpc: DeleteUserFiles(file_manager_service.UserId) returns (file_manager_service.Empty);
     */
    deleteUserFiles(input: UserId, options?: RpcOptions): UnaryCall<UserId, Empty>;
}
/**
 * ———————————————————— File Manager Private Service ————————————————————
 *
 * @generated from protobuf service file_manager_service.FileManagerPrivate
 */
export class FileManagerPrivateClient implements IFileManagerPrivateClient, ServiceInfo {
    typeName = FileManagerPrivate.typeName;
    methods = FileManagerPrivate.methods;
    options = FileManagerPrivate.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: UploadFile(stream file_manager_service.UploadFileStream) returns (file_manager_service.HashSum);
     */
    uploadFile(options?: RpcOptions): ClientStreamingCall<UploadFileStream, HashSum> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<UploadFileStream, HashSum>("clientStreaming", this._transport, method, opt);
    }
    /**
     * @generated from protobuf rpc: DownloadFile(file_manager_service.HashSum) returns (stream file_manager_service.DownloadFileStream);
     */
    downloadFile(input: HashSum, options?: RpcOptions): ServerStreamingCall<HashSum, DownloadFileStream> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<HashSum, DownloadFileStream>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetFileInfo(file_manager_service.HashSum) returns (file_manager_service.FileInfo);
     */
    getFileInfo(input: HashSum, options?: RpcOptions): UnaryCall<HashSum, FileInfo> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<HashSum, FileInfo>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteUserFiles(file_manager_service.UserId) returns (file_manager_service.Empty);
     */
    deleteUserFiles(input: UserId, options?: RpcOptions): UnaryCall<UserId, Empty> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UserId, Empty>("unary", this._transport, method, opt, input);
    }
}
/**
 * ———————————————————— File Manager Public Service ————————————————————
 *
 * @generated from protobuf service file_manager_service.FileManagerPublic
 */
export interface IFileManagerPublicClient {
    /**
     * @generated from protobuf rpc: DownloadFile(file_manager_service.HashSum) returns (stream file_manager_service.DownloadFileStream);
     */
    downloadFile(input: HashSum, options?: RpcOptions): ServerStreamingCall<HashSum, DownloadFileStream>;
    /**
     * @generated from protobuf rpc: GetFileInfo(file_manager_service.HashSum) returns (file_manager_service.FileInfo);
     */
    getFileInfo(input: HashSum, options?: RpcOptions): UnaryCall<HashSum, FileInfo>;
}
/**
 * ———————————————————— File Manager Public Service ————————————————————
 *
 * @generated from protobuf service file_manager_service.FileManagerPublic
 */
export class FileManagerPublicClient implements IFileManagerPublicClient, ServiceInfo {
    typeName = FileManagerPublic.typeName;
    methods = FileManagerPublic.methods;
    options = FileManagerPublic.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: DownloadFile(file_manager_service.HashSum) returns (stream file_manager_service.DownloadFileStream);
     */
    downloadFile(input: HashSum, options?: RpcOptions): ServerStreamingCall<HashSum, DownloadFileStream> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<HashSum, DownloadFileStream>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetFileInfo(file_manager_service.HashSum) returns (file_manager_service.FileInfo);
     */
    getFileInfo(input: HashSum, options?: RpcOptions): UnaryCall<HashSum, FileInfo> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<HashSum, FileInfo>("unary", this._transport, method, opt, input);
    }
}
