import {ElNotification} from "element-plus";
import {ErrorCodeEnum} from "@/interfaces/error.ts";

export const showErrorNotification = (errorCode: string, customMessage = "") => {
    const errorMessage = getErrorMessage(errorCode);

    ElNotification({
        title: 'Error',
        message: customMessage || errorMessage,
        type: 'error',
        position: 'bottom-right',
    });
};

const getErrorMessage = (code: string) => {
    switch (code) {
        case ErrorCodeEnum.CANCELLED:
            return "Request was cancelled.";
        case ErrorCodeEnum.UNKNOWN:
            return "Unknown error occurred.";
        case ErrorCodeEnum.INVALID_ARGUMENT:
            return "Invalid argument provided.";
        case ErrorCodeEnum.DEADLINE_EXCEEDED:
            return "Deadline exceeded.";
        case ErrorCodeEnum.NOT_FOUND:
            return "Resource not found.";
        case ErrorCodeEnum.ALREADY_EXISTS:
            return "Resource already exists.";
        case ErrorCodeEnum.PERMISSION_DENIED:
            return "Permission denied.";
        case ErrorCodeEnum.UNAUTHENTICATED:
            return "Unauthenticated.";
        case ErrorCodeEnum.RESOURCE_EXHAUSTED:
            return "Resource exhausted.";
        case ErrorCodeEnum.FAILED_PRECONDITION:
            return "Failed precondition.";
        case ErrorCodeEnum.ABORTED:
            return "Operation aborted.";
        case ErrorCodeEnum.OUT_OF_RANGE:
            return "Out of range.";
        case ErrorCodeEnum.UNIMPLEMENTED:
            return "Unimplemented feature.";
        case ErrorCodeEnum.INTERNAL:
            return "Internal error.";
        case ErrorCodeEnum.UNAVAILABLE:
            return "Service unavailable.";
        case ErrorCodeEnum.DATA_LOSS:
            return "Data loss.";
        default:
            return "An unexpected error occurred.";
    }
};
