export enum ErrorCodeEnum {
    CANCELLED= "CANCELLED",
    UNKNOWN = "UNKNOWN",
    INVALID_ARGUMENT= "INVALID_ARGUMENT",
    DEADLINE_EXCEEDED= "DEADLINE_EXCEEDED",
    NOT_FOUND= "NOT_FOUND",
    ALREADY_EXISTS= "ALREADY_EXISTS",
    PERMISSION_DENIED= "PERMISSION_DENIED",
    UNAUTHENTICATED= "UNAUTHENTICATED",
    RESOURCE_EXHAUSTED= "RESOURCE_EXHAUSTED",
    FAILED_PRECONDITION= "FAILED_PRECONDITION",
    ABORTED= "ABORTED",
    OUT_OF_RANGE= "OUT_OF_RANGE",
    UNIMPLEMENTED= "UNIMPLEMENTED",
    INTERNAL= "INTERNAL",
    UNAVAILABLE= "UNAVAILABLE",
    DATA_LOSS= "DATA_LOSS",
};

export interface RpcError {
    code: ErrorCodeEnum
}