import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport'
import { metaOptions } from '@/api/auth.ts'
import { OrganizationsClient } from '@/protogen/organizations_service/organizations_service.client.ts'
import {
  Empty,
  GetAllOrganizationsInfoResponse,
  GetAllOrganizationsPermissionsResponse,
  GetOrganizationInfoResponse,
  IsValidResponse,
  OrganizationCode,
  OrganizationId,
  OrganizationInfo,
  Permissions,
  UpdateAdminRequest,
  UpdateOrganizationInfoRequest,
  UsersResponse,
} from '@/protogen/organizations_service/organizations_service.ts'
import { apiRequestWrapper } from '@/helpers/apiWrapper'

const client = new OrganizationsClient(
  new GrpcWebFetchTransport({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
  }),
)

export default () => ({
  createOrganization: async (name: string, pictureFileId: Uint8Array): Promise<OrganizationId> => {
    const request = OrganizationInfo.create({ name, pictureFileId })
    return apiRequestWrapper(async () => {
      const response = await client.createOrganization(request, metaOptions)
      return response.response
    })
  },
  updateOrganizationInfo: async (organizationId: number, name: string, pictureFileId: Uint8Array): Promise<void> => {
    const request = UpdateOrganizationInfoRequest.create({ organizationId, organizationInfo: { name, pictureFileId } })
    return apiRequestWrapper(async () => {
      await client.updateOrganizationInfo(request, metaOptions)
    })
  },
  deleteOrganization: async (organizationId: number): Promise<void> => {
    const request = OrganizationId.create({ organizationId })
    return apiRequestWrapper(async () => {
      await client.deleteOrganization(request, metaOptions)
    })
  },
  getDeletedOrganizationsInfo: async (): Promise<GetAllOrganizationsInfoResponse> => {
    const request = Empty.create()
    return apiRequestWrapper(async () => {
      const response = await client.getDeletedOrganizationsInfo(request, metaOptions)
      return response.response
    })
  },
  restoreOrganization: async (organizationId: number): Promise<void> => {
    const request = OrganizationId.create({ organizationId })
    return apiRequestWrapper(async () => {
      await client.restoreOrganization(request, metaOptions)
    })
  },
  isValidOrganization: async (organizationId: number): Promise<IsValidResponse> => {
    const request = OrganizationId.create({ organizationId })
    return apiRequestWrapper(async () => {
      const response = await client.isValidOrganization(request, metaOptions)
      return response.response
    })
  },
  getOrganizationCode: async (organizationId: number): Promise<OrganizationCode> => {
    const request = OrganizationId.create({ organizationId })
    return apiRequestWrapper(async () => {
      const response = await client.getOrganizationCode(request, metaOptions)
      return response.response
    })
  },
  validateOrganizationCode: async (code: string): Promise<OrganizationId> => {
    const request = OrganizationCode.create({ code })
    return apiRequestWrapper(async () => {
      const response = await client.validateOrganizationCode(request, metaOptions)
      return response.response
    })
  },
  getAdmins: async (organizationId: number): Promise<UsersResponse> => {
    const request = OrganizationId.create({ organizationId })
    return apiRequestWrapper(async () => {
      const response = await client.getAdmins(request, metaOptions)
      return response.response
    })
  },
  addAdmin: async (organizationId: number, userId: number): Promise<void> => {
    const request = UpdateAdminRequest.create({ organizationId, userId })
    return apiRequestWrapper(async () => {
      await client.addAdmin(request, metaOptions)
    })
  },
  removeAdmin: async (organizationId: number, userId: number): Promise<void> => {
    const request = UpdateAdminRequest.create({ organizationId, userId })
    return apiRequestWrapper(async () => {
      await client.removeAdmin(request, metaOptions)
    })
  },
  getOrganizationInfo: async (organizationId: number): Promise<GetOrganizationInfoResponse> => {
    const request = OrganizationId.create({ organizationId })
    return apiRequestWrapper(async () => {
      const response = await client.getOrganizationInfo(request, metaOptions)
      return response.response
    })
  },
  getAllOrganizationsInfo: async (): Promise<GetAllOrganizationsInfoResponse> => {
    const request = Empty.create()
    return apiRequestWrapper(async () => {
      const response = await client.getAllOrganizationsInfo(request, metaOptions)
      return response.response
    })
  },
  getOrganizationPermissions: async (organizationId: number): Promise<Permissions> => {
    const request = OrganizationId.create({ organizationId })
    return apiRequestWrapper(async () => {
      const response = await client.getOrganizationPermissions(request, metaOptions)
      return response.response
    })
  },
  getAllOrganizationsPermissions: async (): Promise<GetAllOrganizationsPermissionsResponse> => {
    const request = Empty.create()
    return apiRequestWrapper(async () => {
      const response = await client.getAllOrganizationsPermissions(request, metaOptions)
      return response.response
    })
  },
})
