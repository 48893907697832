import { defineStore } from 'pinia'
import api from '@/api/userInfo'
import apiFile from '@/api/fileManager'
import { User } from '@/protogen/userinfo_service/userinfo_service.ts'
import { toHex } from '@/helpers/toHex'

interface State {
  user: User | null
  avatarUrl: string
  loading: boolean
}

export const useUserStore = defineStore('user', {
  state: (): State => {
    return {
      user: null,
      avatarUrl: '',
      loading: false,
    }
  },
  actions: {
    async fetchUser() {
      try {
        this.loading = true
        this.user = await api().getUser(0, 0)
        if (this.user.pictureFileId.length) {
          const avatarResponse = await apiFile().getFile(toHex(this.user.pictureFileId))
          if (avatarResponse) {
            this.avatarUrl = avatarResponse.url
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    clearStore(): void {
      this.user = null
    },
  },
})
