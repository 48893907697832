import api from '@/api/organizations'

import { ErrorCodeEnum } from '@/interfaces/error'
import {
  GetAllOrganizationsInfoResponse,
  OrganizationInfo,
  Permissions,
} from '@/protogen/organizations_service/organizations_service'
import { defineStore } from 'pinia'

export interface OrganizationStated {
  organizationId: number
  organizationInfo: OrganizationInfo
  permissions: Permissions
}

export interface State {
  organizationsList: OrganizationStated[]
  deletedOrganizationsList: OrganizationStated[]
  activeOrganizationId: number
  loading: boolean
  logoUrl: string
}

export const useOrganizationStore = defineStore('organization', {
  state: (): State => ({
    organizationsList: [],
    deletedOrganizationsList: [],
    activeOrganizationId: 0,
    loading: true,
    logoUrl: '',
  }),
  actions: {
    async fetchOrganizations() {
      try {
        this.loading = true
        const orgsRsp = await api().getAllOrganizationsInfo()
        let orgsDeletedRsp = GetAllOrganizationsInfoResponse.create()
        try {
          orgsDeletedRsp = await api().getDeletedOrganizationsInfo()
        } catch (error: any) {
          if (error.code != ErrorCodeEnum.PERMISSION_DENIED) {
            throw error
          }
        }
        const orgPermissionsRsp = await api().getAllOrganizationsPermissions()

        const permissionsMap = new Map<number, Permissions>()
        orgPermissionsRsp.organizationIds.forEach((id, index) => {
          permissionsMap.set(id, orgPermissionsRsp.permissions[index])
        })

        this.organizationsList = orgsRsp.organizationIds.map((id, index) => {
          return {
            organizationId: id,
            organizationInfo: orgsRsp.organizationInfos[index],
            permissions: permissionsMap.get(id) || ({} as Permissions),
          }
        })

        this.deletedOrganizationsList = orgsDeletedRsp.organizationIds.map((id, index) => {
          return {
            organizationId: id,
            organizationInfo: orgsDeletedRsp.organizationInfos[index],
            permissions: permissionsMap.get(id) || ({} as Permissions),
          }
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async ensureOrganizationsLoaded() {
      if (!this.organizationsList.length) {
        await this.fetchOrganizations()
      }

      this.organizationsList = this.organizationsList
        .filter((org) => org.organizationId !== 1)
        .sort((a, b) => a.organizationId - b.organizationId)

      if (this.organizationsList.length) {
        this.activeOrganizationId = this.organizationsList[0].organizationId
      }
    },
  },
})
