// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "file_manager_service.proto" (package "file_manager_service", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message file_manager_service.UserId
 */
export interface UserId {
    /**
     * @generated from protobuf field: int32 userId = 1;
     */
    userId: number;
}
/**
 * @generated from protobuf message file_manager_service.Empty
 */
export interface Empty {
}
/**
 * @generated from protobuf message file_manager_service.HashSum
 */
export interface HashSum {
    /**
     * @generated from protobuf field: bytes hashSum = 1;
     */
    hashSum: Uint8Array;
}
/**
 * @generated from protobuf message file_manager_service.FileInfo
 */
export interface FileInfo {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: uint32 size = 2;
     */
    size: number;
    /**
     * @generated from protobuf field: int32 ownerId = 3;
     */
    ownerId: number;
    /**
     * @generated from protobuf field: file_manager_service.AccessType accessType = 4;
     */
    accessType: AccessType;
    /**
     * @generated from protobuf field: int32 courseId = 5;
     */
    courseId: number;
    /**
     * @generated from protobuf field: repeated int32 guestIds = 6;
     */
    guestIds: number[];
}
/**
 * @generated from protobuf message file_manager_service.UploadFileEntry
 */
export interface UploadFileEntry {
    /**
     * @generated from protobuf field: bytes hashSum = 1;
     */
    hashSum: Uint8Array;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: uint32 size = 4;
     */
    size: number;
    /**
     * @generated from protobuf field: int32 ownerId = 5;
     */
    ownerId: number;
    /**
     * @generated from protobuf field: file_manager_service.AccessType accessType = 6;
     */
    accessType: AccessType;
    /**
     * @generated from protobuf field: int32 courseId = 7;
     */
    courseId: number;
    /**
     * @generated from protobuf field: repeated int32 guestIds = 8;
     */
    guestIds: number[];
}
/**
 * @generated from protobuf message file_manager_service.UploadFileStream
 */
export interface UploadFileStream {
    /**
     * @generated from protobuf oneof: data
     */
    data: {
        oneofKind: "uploadFileEntry";
        /**
         * @generated from protobuf field: file_manager_service.UploadFileEntry uploadFileEntry = 1;
         */
        uploadFileEntry: UploadFileEntry;
    } | {
        oneofKind: "chunkData";
        /**
         * @generated from protobuf field: bytes chunkData = 2;
         */
        chunkData: Uint8Array;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message file_manager_service.DownloadFileStream
 */
export interface DownloadFileStream {
    /**
     * @generated from protobuf oneof: data
     */
    data: {
        oneofKind: "fileInfo";
        /**
         * @generated from protobuf field: file_manager_service.FileInfo fileInfo = 1;
         */
        fileInfo: FileInfo;
    } | {
        oneofKind: "chunkData";
        /**
         * @generated from protobuf field: bytes chunkData = 2;
         */
        chunkData: Uint8Array;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf enum file_manager_service.AccessType
 */
export enum AccessType {
    /**
     * @generated from protobuf enum value: PublicOverall = 0;
     */
    PublicOverall = 0,
    /**
     * @generated from protobuf enum value: PublicCourse = 1;
     */
    PublicCourse = 1,
    /**
     * @generated from protobuf enum value: PrivateCourse = 2;
     */
    PrivateCourse = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class UserId$Type extends MessageType<UserId> {
    constructor() {
        super("file_manager_service.UserId", [
            { no: 1, name: "userId", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<UserId>): UserId {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userId = 0;
        if (value !== undefined)
            reflectionMergePartial<UserId>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserId): UserId {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 userId */ 1:
                    message.userId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserId, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 userId = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message file_manager_service.UserId
 */
export const UserId = new UserId$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Empty$Type extends MessageType<Empty> {
    constructor() {
        super("file_manager_service.Empty", []);
    }
    create(value?: PartialMessage<Empty>): Empty {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<Empty>(this, message, value);
        return message;
    }
    internalBinaryRead(target?: Empty): Empty {
        return target ?? this.create();
    }
    internalBinaryWrite(message: Empty, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message file_manager_service.Empty
 */
export const Empty = new Empty$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HashSum$Type extends MessageType<HashSum> {
    constructor() {
        super("file_manager_service.HashSum", [
            { no: 1, name: "hashSum", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<HashSum>): HashSum {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.hashSum = new Uint8Array(0);
        if (value !== undefined)
            reflectionMergePartial<HashSum>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HashSum): HashSum {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes hashSum */ 1:
                    message.hashSum = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HashSum, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes hashSum = 1; */
        if (message.hashSum.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.hashSum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message file_manager_service.HashSum
 */
export const HashSum = new HashSum$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileInfo$Type extends MessageType<FileInfo> {
    constructor() {
        super("file_manager_service.FileInfo", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "size", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "ownerId", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "accessType", kind: "enum", T: () => ["file_manager_service.AccessType", AccessType] },
            { no: 5, name: "courseId", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "guestIds", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<FileInfo>): FileInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.size = 0;
        message.ownerId = 0;
        message.accessType = 0;
        message.courseId = 0;
        message.guestIds = [];
        if (value !== undefined)
            reflectionMergePartial<FileInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FileInfo): FileInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* uint32 size */ 2:
                    message.size = reader.uint32();
                    break;
                case /* int32 ownerId */ 3:
                    message.ownerId = reader.int32();
                    break;
                case /* file_manager_service.AccessType accessType */ 4:
                    message.accessType = reader.int32();
                    break;
                case /* int32 courseId */ 5:
                    message.courseId = reader.int32();
                    break;
                case /* repeated int32 guestIds */ 6:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.guestIds.push(reader.int32());
                    else
                        message.guestIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FileInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* uint32 size = 2; */
        if (message.size !== 0)
            writer.tag(2, WireType.Varint).uint32(message.size);
        /* int32 ownerId = 3; */
        if (message.ownerId !== 0)
            writer.tag(3, WireType.Varint).int32(message.ownerId);
        /* file_manager_service.AccessType accessType = 4; */
        if (message.accessType !== 0)
            writer.tag(4, WireType.Varint).int32(message.accessType);
        /* int32 courseId = 5; */
        if (message.courseId !== 0)
            writer.tag(5, WireType.Varint).int32(message.courseId);
        /* repeated int32 guestIds = 6; */
        if (message.guestIds.length) {
            writer.tag(6, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.guestIds.length; i++)
                writer.int32(message.guestIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message file_manager_service.FileInfo
 */
export const FileInfo = new FileInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadFileEntry$Type extends MessageType<UploadFileEntry> {
    constructor() {
        super("file_manager_service.UploadFileEntry", [
            { no: 1, name: "hashSum", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "size", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "ownerId", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "accessType", kind: "enum", T: () => ["file_manager_service.AccessType", AccessType] },
            { no: 7, name: "courseId", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "guestIds", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<UploadFileEntry>): UploadFileEntry {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.hashSum = new Uint8Array(0);
        message.name = "";
        message.size = 0;
        message.ownerId = 0;
        message.accessType = 0;
        message.courseId = 0;
        message.guestIds = [];
        if (value !== undefined)
            reflectionMergePartial<UploadFileEntry>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UploadFileEntry): UploadFileEntry {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes hashSum */ 1:
                    message.hashSum = reader.bytes();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* uint32 size */ 4:
                    message.size = reader.uint32();
                    break;
                case /* int32 ownerId */ 5:
                    message.ownerId = reader.int32();
                    break;
                case /* file_manager_service.AccessType accessType */ 6:
                    message.accessType = reader.int32();
                    break;
                case /* int32 courseId */ 7:
                    message.courseId = reader.int32();
                    break;
                case /* repeated int32 guestIds */ 8:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.guestIds.push(reader.int32());
                    else
                        message.guestIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UploadFileEntry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes hashSum = 1; */
        if (message.hashSum.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.hashSum);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* uint32 size = 4; */
        if (message.size !== 0)
            writer.tag(4, WireType.Varint).uint32(message.size);
        /* int32 ownerId = 5; */
        if (message.ownerId !== 0)
            writer.tag(5, WireType.Varint).int32(message.ownerId);
        /* file_manager_service.AccessType accessType = 6; */
        if (message.accessType !== 0)
            writer.tag(6, WireType.Varint).int32(message.accessType);
        /* int32 courseId = 7; */
        if (message.courseId !== 0)
            writer.tag(7, WireType.Varint).int32(message.courseId);
        /* repeated int32 guestIds = 8; */
        if (message.guestIds.length) {
            writer.tag(8, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.guestIds.length; i++)
                writer.int32(message.guestIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message file_manager_service.UploadFileEntry
 */
export const UploadFileEntry = new UploadFileEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadFileStream$Type extends MessageType<UploadFileStream> {
    constructor() {
        super("file_manager_service.UploadFileStream", [
            { no: 1, name: "uploadFileEntry", kind: "message", oneof: "data", T: () => UploadFileEntry },
            { no: 2, name: "chunkData", kind: "scalar", oneof: "data", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<UploadFileStream>): UploadFileStream {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.data = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<UploadFileStream>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UploadFileStream): UploadFileStream {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* file_manager_service.UploadFileEntry uploadFileEntry */ 1:
                    message.data = {
                        oneofKind: "uploadFileEntry",
                        uploadFileEntry: UploadFileEntry.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).uploadFileEntry)
                    };
                    break;
                case /* bytes chunkData */ 2:
                    message.data = {
                        oneofKind: "chunkData",
                        chunkData: reader.bytes()
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UploadFileStream, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* file_manager_service.UploadFileEntry uploadFileEntry = 1; */
        if (message.data.oneofKind === "uploadFileEntry")
            UploadFileEntry.internalBinaryWrite(message.data.uploadFileEntry, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bytes chunkData = 2; */
        if (message.data.oneofKind === "chunkData")
            writer.tag(2, WireType.LengthDelimited).bytes(message.data.chunkData);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message file_manager_service.UploadFileStream
 */
export const UploadFileStream = new UploadFileStream$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DownloadFileStream$Type extends MessageType<DownloadFileStream> {
    constructor() {
        super("file_manager_service.DownloadFileStream", [
            { no: 1, name: "fileInfo", kind: "message", oneof: "data", T: () => FileInfo },
            { no: 2, name: "chunkData", kind: "scalar", oneof: "data", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<DownloadFileStream>): DownloadFileStream {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.data = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<DownloadFileStream>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DownloadFileStream): DownloadFileStream {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* file_manager_service.FileInfo fileInfo */ 1:
                    message.data = {
                        oneofKind: "fileInfo",
                        fileInfo: FileInfo.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).fileInfo)
                    };
                    break;
                case /* bytes chunkData */ 2:
                    message.data = {
                        oneofKind: "chunkData",
                        chunkData: reader.bytes()
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DownloadFileStream, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* file_manager_service.FileInfo fileInfo = 1; */
        if (message.data.oneofKind === "fileInfo")
            FileInfo.internalBinaryWrite(message.data.fileInfo, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bytes chunkData = 2; */
        if (message.data.oneofKind === "chunkData")
            writer.tag(2, WireType.LengthDelimited).bytes(message.data.chunkData);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message file_manager_service.DownloadFileStream
 */
export const DownloadFileStream = new DownloadFileStream$Type();
/**
 * @generated ServiceType for protobuf service file_manager_service.FileManagerPrivate
 */
export const FileManagerPrivate = new ServiceType("file_manager_service.FileManagerPrivate", [
    { name: "UploadFile", clientStreaming: true, options: {}, I: UploadFileStream, O: HashSum },
    { name: "DownloadFile", serverStreaming: true, options: {}, I: HashSum, O: DownloadFileStream },
    { name: "GetFileInfo", options: {}, I: HashSum, O: FileInfo },
    { name: "DeleteUserFiles", options: {}, I: UserId, O: Empty }
]);
/**
 * @generated ServiceType for protobuf service file_manager_service.FileManagerPublic
 */
export const FileManagerPublic = new ServiceType("file_manager_service.FileManagerPublic", [
    { name: "DownloadFile", serverStreaming: true, options: {}, I: HashSum, O: DownloadFileStream },
    { name: "GetFileInfo", options: {}, I: HashSum, O: FileInfo }
]);
