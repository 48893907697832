import { defineStore } from 'pinia'
import { useNotificationsStore } from './notifications'
import { useUserStore } from './userStore'
import { clearAuthTokens } from './useLocalStorage'

interface State {
  email: string | null
  hasAccount: boolean
  hasMobileAuth: boolean
  isMobileAuth: boolean
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    email: null,
    hasAccount: false,
    hasMobileAuth: false,
    isMobileAuth: false,
  }),
  actions: {
    setEmail(email: string) {
      this.email = email
    },
    setHasAccount(hasAccount: boolean) {
      this.hasAccount = hasAccount
    },
    setHasMobileAuth(hasMobileAuth: boolean) {
      this.hasMobileAuth = hasMobileAuth
    },
    setIsMobileAuth(isMobileAuth: boolean) {
      this.isMobileAuth = isMobileAuth
    },
    clearStore() {
      this.email = null
      this.hasAccount = false
      this.hasMobileAuth = false
      this.isMobileAuth = false
    },
    logout() {
      this.clearStore()

      const notificationsStore = useNotificationsStore()
      const userInfoStore = useUserStore()

      notificationsStore.clearFetchInterval()
      userInfoStore.clearStore()
      clearAuthTokens()

      window.location.reload()
    },
  },
})
