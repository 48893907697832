import { NotificationsPublicClient } from '@/protogen/notifications_service/notifications_service.client'
import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport'
import { metaOptions } from '@/api/auth.ts'
import {
  Filters,
  Notifications,
  NotificationNumber,
  Ids,
  Subscription,
  IsSubscribedResponce,
} from '@/protogen/notifications_service/notifications_service'
import { apiRequestWrapper } from '@/helpers/apiWrapper'

const client = new NotificationsPublicClient(
  new GrpcWebFetchTransport({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
  }),
)

export default () => ({
  getNotifications: async (filters: Filters): Promise<Notifications> => {
    return apiRequestWrapper(async () => {
      const response = await client.getNotifications(filters, metaOptions)
      return response.response
    })
  },
  getNotificationNumber: async (filters: Filters): Promise<NotificationNumber> => {
    return apiRequestWrapper(async () => {
      const response = await client.getNotificationNumber(filters, metaOptions)
      return response.response
    })
  },
  markRead: async (ids: Ids): Promise<void> => {
    return apiRequestWrapper(async () => {
      await client.markRead(ids, metaOptions)
    })
  },
  subscribe: async (subscription: Subscription): Promise<void> => {
    return apiRequestWrapper(async () => {
      await client.subscribe(subscription, metaOptions)
    })
  },
  unsubscribe: async (subscription: Subscription): Promise<void> => {
    return apiRequestWrapper(async () => {
      await client.unsubscribe(subscription, metaOptions)
    })
  },
  isSubscribed: async (subscription: Subscription): Promise<IsSubscribedResponce> => {
    return apiRequestWrapper(async () => {
      const response = await client.isSubscribed(subscription, metaOptions)
      return response.response
    })
  },
})
