// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "notifications_service.proto" (package "notifications_service", syntax proto3)
// tslint:disable
//
// Protobuf definition file
//
import { NotificationsPrivate } from "./notifications_service";
import type { SubscriptionRequest } from "./notifications_service";
import type { CreateNotificationRequest } from "./notifications_service";
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { NotificationsPublic } from "./notifications_service";
import type { Subscriptions } from "./notifications_service";
import type { IsSubscribedResponce } from "./notifications_service";
import type { Subscription } from "./notifications_service";
import type { Empty } from "./notifications_service";
import type { Ids } from "./notifications_service";
import type { NotificationNumber } from "./notifications_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Notifications } from "./notifications_service";
import type { Filters } from "./notifications_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * The service definition.
 *
 * @generated from protobuf service notifications_service.NotificationsPublic
 */
export interface INotificationsPublicClient {
    /**
     * @generated from protobuf rpc: GetNotifications(notifications_service.Filters) returns (notifications_service.Notifications);
     */
    getNotifications(input: Filters, options?: RpcOptions): UnaryCall<Filters, Notifications>;
    /**
     * @generated from protobuf rpc: GetNotificationNumber(notifications_service.Filters) returns (notifications_service.NotificationNumber);
     */
    getNotificationNumber(input: Filters, options?: RpcOptions): UnaryCall<Filters, NotificationNumber>;
    /**
     * @generated from protobuf rpc: MarkRead(notifications_service.Ids) returns (notifications_service.Empty);
     */
    markRead(input: Ids, options?: RpcOptions): UnaryCall<Ids, Empty>;
    /**
     * @generated from protobuf rpc: Subscribe(notifications_service.Subscription) returns (notifications_service.Empty);
     */
    subscribe(input: Subscription, options?: RpcOptions): UnaryCall<Subscription, Empty>;
    /**
     * @generated from protobuf rpc: Unsubscribe(notifications_service.Subscription) returns (notifications_service.Empty);
     */
    unsubscribe(input: Subscription, options?: RpcOptions): UnaryCall<Subscription, Empty>;
    /**
     * @generated from protobuf rpc: IsSubscribed(notifications_service.Subscription) returns (notifications_service.IsSubscribedResponce);
     */
    isSubscribed(input: Subscription, options?: RpcOptions): UnaryCall<Subscription, IsSubscribedResponce>;
    /**
     * @generated from protobuf rpc: GetSubscriptions(notifications_service.Empty) returns (notifications_service.Subscriptions);
     */
    getSubscriptions(input: Empty, options?: RpcOptions): UnaryCall<Empty, Subscriptions>;
}
/**
 * The service definition.
 *
 * @generated from protobuf service notifications_service.NotificationsPublic
 */
export class NotificationsPublicClient implements INotificationsPublicClient, ServiceInfo {
    typeName = NotificationsPublic.typeName;
    methods = NotificationsPublic.methods;
    options = NotificationsPublic.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetNotifications(notifications_service.Filters) returns (notifications_service.Notifications);
     */
    getNotifications(input: Filters, options?: RpcOptions): UnaryCall<Filters, Notifications> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<Filters, Notifications>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetNotificationNumber(notifications_service.Filters) returns (notifications_service.NotificationNumber);
     */
    getNotificationNumber(input: Filters, options?: RpcOptions): UnaryCall<Filters, NotificationNumber> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<Filters, NotificationNumber>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: MarkRead(notifications_service.Ids) returns (notifications_service.Empty);
     */
    markRead(input: Ids, options?: RpcOptions): UnaryCall<Ids, Empty> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<Ids, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Subscribe(notifications_service.Subscription) returns (notifications_service.Empty);
     */
    subscribe(input: Subscription, options?: RpcOptions): UnaryCall<Subscription, Empty> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<Subscription, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Unsubscribe(notifications_service.Subscription) returns (notifications_service.Empty);
     */
    unsubscribe(input: Subscription, options?: RpcOptions): UnaryCall<Subscription, Empty> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<Subscription, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: IsSubscribed(notifications_service.Subscription) returns (notifications_service.IsSubscribedResponce);
     */
    isSubscribed(input: Subscription, options?: RpcOptions): UnaryCall<Subscription, IsSubscribedResponce> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<Subscription, IsSubscribedResponce>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSubscriptions(notifications_service.Empty) returns (notifications_service.Subscriptions);
     */
    getSubscriptions(input: Empty, options?: RpcOptions): UnaryCall<Empty, Subscriptions> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, Subscriptions>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service notifications_service.NotificationsPrivate
 */
export interface INotificationsPrivateClient {
    /**
     * @generated from protobuf rpc: CreateNotification(notifications_service.CreateNotificationRequest) returns (notifications_service.Empty);
     */
    createNotification(input: CreateNotificationRequest, options?: RpcOptions): UnaryCall<CreateNotificationRequest, Empty>;
    /**
     * @generated from protobuf rpc: RemoveAllHolderSubscriptions(notifications_service.Subscription) returns (notifications_service.Empty);
     */
    removeAllHolderSubscriptions(input: Subscription, options?: RpcOptions): UnaryCall<Subscription, Empty>;
    /**
     * @generated from protobuf rpc: RemoveSubscription(notifications_service.SubscriptionRequest) returns (notifications_service.Empty);
     */
    removeSubscription(input: SubscriptionRequest, options?: RpcOptions): UnaryCall<SubscriptionRequest, Empty>;
    /**
     * @generated from protobuf rpc: AddSubscription(notifications_service.SubscriptionRequest) returns (notifications_service.Empty);
     */
    addSubscription(input: SubscriptionRequest, options?: RpcOptions): UnaryCall<SubscriptionRequest, Empty>;
}
/**
 * @generated from protobuf service notifications_service.NotificationsPrivate
 */
export class NotificationsPrivateClient implements INotificationsPrivateClient, ServiceInfo {
    typeName = NotificationsPrivate.typeName;
    methods = NotificationsPrivate.methods;
    options = NotificationsPrivate.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: CreateNotification(notifications_service.CreateNotificationRequest) returns (notifications_service.Empty);
     */
    createNotification(input: CreateNotificationRequest, options?: RpcOptions): UnaryCall<CreateNotificationRequest, Empty> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateNotificationRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveAllHolderSubscriptions(notifications_service.Subscription) returns (notifications_service.Empty);
     */
    removeAllHolderSubscriptions(input: Subscription, options?: RpcOptions): UnaryCall<Subscription, Empty> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<Subscription, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveSubscription(notifications_service.SubscriptionRequest) returns (notifications_service.Empty);
     */
    removeSubscription(input: SubscriptionRequest, options?: RpcOptions): UnaryCall<SubscriptionRequest, Empty> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<SubscriptionRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddSubscription(notifications_service.SubscriptionRequest) returns (notifications_service.Empty);
     */
    addSubscription(input: SubscriptionRequest, options?: RpcOptions): UnaryCall<SubscriptionRequest, Empty> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<SubscriptionRequest, Empty>("unary", this._transport, method, opt, input);
    }
}
