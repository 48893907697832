import { RouteLocationNormalized } from 'vue-router'

import ContestRoutes from '../modules/contest'
import ChallengeRoutes from '../modules/challenge'
import TestRoutes from '../modules/test'

import { useCoursesStore } from '@/store/courses'
import { useOrganizationStore } from '@/store/organization'

export default [
  {
    path: 'courses',
    component: () => import('@/views/courses/course/Courses.vue'),
    name: 'Courses',
  },
  {
    path: 'courses/:courseId',
    name: 'CourseContent',
    children: [
      {
        path: 'grades',
        component: () => import('@/views/courses/course/CoursesGrade.vue'),
        name: 'Grades',
      },
      {
        path: '',
        component: () => import('@/views/courses/course/Course.vue'),
        name: 'Course',
        children: [
          {
            path: 'edit',
            component: () => import('@/views/courses/course/Course.vue'),
            name: 'Edit',
          },
        ],
      },
      ...ContestRoutes,
      ...TestRoutes,
      ...ChallengeRoutes,
    ],
    beforeEnter: async (to: RouteLocationNormalized) => {
      const organizationsStore = useOrganizationStore()
      const courseStore = useCoursesStore()

      await organizationsStore.ensureOrganizationsLoaded()

      const courses = await courseStore.ensureCoursesLoaded(organizationsStore.activeOrganizationId)

      const id = parseInt(to.params.courseId as string)

      if (!courses.includes(id))
        return {
          name: 'NotFound',
          params: { pathMatch: to.path.substring(1).split('/') },
          query: to.query,
          hash: to.hash,
        }
    },
  },
]
