// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "userinfo_service.proto" (package "userinfo_service", syntax proto3)
// tslint:disable
import { UserInfoPublic } from "./userinfo_service";
import type { UsersRequest } from "./userinfo_service";
import type { Users } from "./userinfo_service";
import type { MemberRequest } from "./userinfo_service";
import type { InviteCode } from "./userinfo_service";
import type { PendingRequests } from "./userinfo_service";
import type { Filters } from "./userinfo_service";
import type { RequestInfo } from "./userinfo_service";
import type { InformationChangeEntry } from "./userinfo_service";
import type { ApprovalRequestEntry } from "./userinfo_service";
import type { UserEmailRequest } from "./userinfo_service";
import type { User } from "./userinfo_service";
import type { UserRequest } from "./userinfo_service";
import type { University } from "./userinfo_service";
import type { Universities } from "./userinfo_service";
import type { Empty } from "./userinfo_service";
import type { Statuses } from "./userinfo_service";
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { UserInfoPrivate } from "./userinfo_service";
import type { Id } from "./userinfo_service";
import type { Email } from "./userinfo_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Ids } from "./userinfo_service";
import type { Emails } from "./userinfo_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * ———————————————————— UserInfo Service ————————————————————
 *
 * @generated from protobuf service userinfo_service.UserInfoPrivate
 */
export interface IUserInfoPrivateClient {
    /**
     * @generated from protobuf rpc: GetUserIds(userinfo_service.Emails) returns (userinfo_service.Ids);
     */
    getUserIds(input: Emails, options?: RpcOptions): UnaryCall<Emails, Ids>;
    /**
     * @generated from protobuf rpc: CreateUser(userinfo_service.Email) returns (userinfo_service.Id);
     */
    createUser(input: Email, options?: RpcOptions): UnaryCall<Email, Id>;
}
/**
 * ———————————————————— UserInfo Service ————————————————————
 *
 * @generated from protobuf service userinfo_service.UserInfoPrivate
 */
export class UserInfoPrivateClient implements IUserInfoPrivateClient, ServiceInfo {
    typeName = UserInfoPrivate.typeName;
    methods = UserInfoPrivate.methods;
    options = UserInfoPrivate.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetUserIds(userinfo_service.Emails) returns (userinfo_service.Ids);
     */
    getUserIds(input: Emails, options?: RpcOptions): UnaryCall<Emails, Ids> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<Emails, Ids>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateUser(userinfo_service.Email) returns (userinfo_service.Id);
     */
    createUser(input: Email, options?: RpcOptions): UnaryCall<Email, Id> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<Email, Id>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service userinfo_service.UserInfoPublic
 */
export interface IUserInfoPublicClient {
    /**
     * @generated from protobuf rpc: GetAccountStatuses(userinfo_service.Emails) returns (userinfo_service.Statuses);
     */
    getAccountStatuses(input: Emails, options?: RpcOptions): UnaryCall<Emails, Statuses>;
    /**
     * @generated from protobuf rpc: GetUniversities(userinfo_service.Empty) returns (userinfo_service.Universities);
     */
    getUniversities(input: Empty, options?: RpcOptions): UnaryCall<Empty, Universities>;
    /**
     * @generated from protobuf rpc: GetUniversity(userinfo_service.Id) returns (userinfo_service.University);
     */
    getUniversity(input: Id, options?: RpcOptions): UnaryCall<Id, University>;
    /**
     * @generated from protobuf rpc: GetUser(userinfo_service.UserRequest) returns (userinfo_service.User);
     */
    getUser(input: UserRequest, options?: RpcOptions): UnaryCall<UserRequest, User>;
    /**
     * @generated from protobuf rpc: GetUserIdByEmail(userinfo_service.UserEmailRequest) returns (userinfo_service.Id);
     */
    getUserIdByEmail(input: UserEmailRequest, options?: RpcOptions): UnaryCall<UserEmailRequest, Id>;
    /**
     * @generated from protobuf rpc: RequestApproval(userinfo_service.ApprovalRequestEntry) returns (userinfo_service.Empty);
     */
    requestApproval(input: ApprovalRequestEntry, options?: RpcOptions): UnaryCall<ApprovalRequestEntry, Empty>;
    /**
     * @generated from protobuf rpc: RequestInformationChange(userinfo_service.InformationChangeEntry) returns (userinfo_service.Empty);
     */
    requestInformationChange(input: InformationChangeEntry, options?: RpcOptions): UnaryCall<InformationChangeEntry, Empty>;
    /**
     * @generated from protobuf rpc: RevokeRequest(userinfo_service.Empty) returns (userinfo_service.Empty);
     */
    revokeRequest(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty>;
    /**
     * @generated from protobuf rpc: GetRequestInfo(userinfo_service.Empty) returns (userinfo_service.RequestInfo);
     */
    getRequestInfo(input: Empty, options?: RpcOptions): UnaryCall<Empty, RequestInfo>;
    /**
     * @generated from protobuf rpc: RejectRequest(userinfo_service.Id) returns (userinfo_service.Empty);
     */
    rejectRequest(input: Id, options?: RpcOptions): UnaryCall<Id, Empty>;
    /**
     * @generated from protobuf rpc: ApproveRequest(userinfo_service.Id) returns (userinfo_service.Empty);
     */
    approveRequest(input: Id, options?: RpcOptions): UnaryCall<Id, Empty>;
    /**
     * @generated from protobuf rpc: GetPendingRequests(userinfo_service.Filters) returns (userinfo_service.PendingRequests);
     */
    getPendingRequests(input: Filters, options?: RpcOptions): UnaryCall<Filters, PendingRequests>;
    /**
     * @generated from protobuf rpc: DeleteAllCurrentUserInformation(userinfo_service.Empty) returns (userinfo_service.Empty);
     */
    deleteAllCurrentUserInformation(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty>;
    /**
     * @generated from protobuf rpc: EnterOrganization(userinfo_service.InviteCode) returns (userinfo_service.Empty);
     */
    enterOrganization(input: InviteCode, options?: RpcOptions): UnaryCall<InviteCode, Empty>;
    /**
     * @generated from protobuf rpc: ExitOrganization(userinfo_service.Id) returns (userinfo_service.Empty);
     */
    exitOrganization(input: Id, options?: RpcOptions): UnaryCall<Id, Empty>;
    /**
     * @generated from protobuf rpc: AddOrganizationMember(userinfo_service.MemberRequest) returns (userinfo_service.Empty);
     */
    addOrganizationMember(input: MemberRequest, options?: RpcOptions): UnaryCall<MemberRequest, Empty>;
    /**
     * @generated from protobuf rpc: RemoveOrganizationMember(userinfo_service.MemberRequest) returns (userinfo_service.Empty);
     */
    removeOrganizationMember(input: MemberRequest, options?: RpcOptions): UnaryCall<MemberRequest, Empty>;
    /**
     * @generated from protobuf rpc: GetOrganizationMembers(userinfo_service.Id) returns (userinfo_service.Users);
     */
    getOrganizationMembers(input: Id, options?: RpcOptions): UnaryCall<Id, Users>;
    /**
     * @generated from protobuf rpc: GetCurrentUserMemberships(userinfo_service.Empty) returns (userinfo_service.Ids);
     */
    getCurrentUserMemberships(input: Empty, options?: RpcOptions): UnaryCall<Empty, Ids>;
    /**
     * BULK
     *
     * @generated from protobuf rpc: GetUsers(userinfo_service.UsersRequest) returns (userinfo_service.Users);
     */
    getUsers(input: UsersRequest, options?: RpcOptions): UnaryCall<UsersRequest, Users>;
    /**
     * DEPRECATED
     *
     * @generated from protobuf rpc: GetOrganizationPendingRequests(userinfo_service.Id) returns (userinfo_service.PendingRequests);
     */
    getOrganizationPendingRequests(input: Id, options?: RpcOptions): UnaryCall<Id, PendingRequests>;
    /**
     * @generated from protobuf rpc: GetAllPendingRequests(userinfo_service.Empty) returns (userinfo_service.PendingRequests);
     */
    getAllPendingRequests(input: Empty, options?: RpcOptions): UnaryCall<Empty, PendingRequests>;
    /**
     * @generated from protobuf rpc: GetCurrentUser(userinfo_service.Empty) returns (userinfo_service.User);
     */
    getCurrentUser(input: Empty, options?: RpcOptions): UnaryCall<Empty, User>;
}
/**
 * @generated from protobuf service userinfo_service.UserInfoPublic
 */
export class UserInfoPublicClient implements IUserInfoPublicClient, ServiceInfo {
    typeName = UserInfoPublic.typeName;
    methods = UserInfoPublic.methods;
    options = UserInfoPublic.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetAccountStatuses(userinfo_service.Emails) returns (userinfo_service.Statuses);
     */
    getAccountStatuses(input: Emails, options?: RpcOptions): UnaryCall<Emails, Statuses> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<Emails, Statuses>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUniversities(userinfo_service.Empty) returns (userinfo_service.Universities);
     */
    getUniversities(input: Empty, options?: RpcOptions): UnaryCall<Empty, Universities> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, Universities>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUniversity(userinfo_service.Id) returns (userinfo_service.University);
     */
    getUniversity(input: Id, options?: RpcOptions): UnaryCall<Id, University> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<Id, University>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUser(userinfo_service.UserRequest) returns (userinfo_service.User);
     */
    getUser(input: UserRequest, options?: RpcOptions): UnaryCall<UserRequest, User> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UserRequest, User>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUserIdByEmail(userinfo_service.UserEmailRequest) returns (userinfo_service.Id);
     */
    getUserIdByEmail(input: UserEmailRequest, options?: RpcOptions): UnaryCall<UserEmailRequest, Id> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<UserEmailRequest, Id>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RequestApproval(userinfo_service.ApprovalRequestEntry) returns (userinfo_service.Empty);
     */
    requestApproval(input: ApprovalRequestEntry, options?: RpcOptions): UnaryCall<ApprovalRequestEntry, Empty> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<ApprovalRequestEntry, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RequestInformationChange(userinfo_service.InformationChangeEntry) returns (userinfo_service.Empty);
     */
    requestInformationChange(input: InformationChangeEntry, options?: RpcOptions): UnaryCall<InformationChangeEntry, Empty> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<InformationChangeEntry, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RevokeRequest(userinfo_service.Empty) returns (userinfo_service.Empty);
     */
    revokeRequest(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRequestInfo(userinfo_service.Empty) returns (userinfo_service.RequestInfo);
     */
    getRequestInfo(input: Empty, options?: RpcOptions): UnaryCall<Empty, RequestInfo> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, RequestInfo>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RejectRequest(userinfo_service.Id) returns (userinfo_service.Empty);
     */
    rejectRequest(input: Id, options?: RpcOptions): UnaryCall<Id, Empty> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<Id, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ApproveRequest(userinfo_service.Id) returns (userinfo_service.Empty);
     */
    approveRequest(input: Id, options?: RpcOptions): UnaryCall<Id, Empty> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<Id, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPendingRequests(userinfo_service.Filters) returns (userinfo_service.PendingRequests);
     */
    getPendingRequests(input: Filters, options?: RpcOptions): UnaryCall<Filters, PendingRequests> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<Filters, PendingRequests>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteAllCurrentUserInformation(userinfo_service.Empty) returns (userinfo_service.Empty);
     */
    deleteAllCurrentUserInformation(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: EnterOrganization(userinfo_service.InviteCode) returns (userinfo_service.Empty);
     */
    enterOrganization(input: InviteCode, options?: RpcOptions): UnaryCall<InviteCode, Empty> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<InviteCode, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ExitOrganization(userinfo_service.Id) returns (userinfo_service.Empty);
     */
    exitOrganization(input: Id, options?: RpcOptions): UnaryCall<Id, Empty> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<Id, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddOrganizationMember(userinfo_service.MemberRequest) returns (userinfo_service.Empty);
     */
    addOrganizationMember(input: MemberRequest, options?: RpcOptions): UnaryCall<MemberRequest, Empty> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<MemberRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveOrganizationMember(userinfo_service.MemberRequest) returns (userinfo_service.Empty);
     */
    removeOrganizationMember(input: MemberRequest, options?: RpcOptions): UnaryCall<MemberRequest, Empty> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<MemberRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetOrganizationMembers(userinfo_service.Id) returns (userinfo_service.Users);
     */
    getOrganizationMembers(input: Id, options?: RpcOptions): UnaryCall<Id, Users> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<Id, Users>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCurrentUserMemberships(userinfo_service.Empty) returns (userinfo_service.Ids);
     */
    getCurrentUserMemberships(input: Empty, options?: RpcOptions): UnaryCall<Empty, Ids> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, Ids>("unary", this._transport, method, opt, input);
    }
    /**
     * BULK
     *
     * @generated from protobuf rpc: GetUsers(userinfo_service.UsersRequest) returns (userinfo_service.Users);
     */
    getUsers(input: UsersRequest, options?: RpcOptions): UnaryCall<UsersRequest, Users> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<UsersRequest, Users>("unary", this._transport, method, opt, input);
    }
    /**
     * DEPRECATED
     *
     * @generated from protobuf rpc: GetOrganizationPendingRequests(userinfo_service.Id) returns (userinfo_service.PendingRequests);
     */
    getOrganizationPendingRequests(input: Id, options?: RpcOptions): UnaryCall<Id, PendingRequests> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<Id, PendingRequests>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAllPendingRequests(userinfo_service.Empty) returns (userinfo_service.PendingRequests);
     */
    getAllPendingRequests(input: Empty, options?: RpcOptions): UnaryCall<Empty, PendingRequests> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, PendingRequests>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCurrentUser(userinfo_service.Empty) returns (userinfo_service.User);
     */
    getCurrentUser(input: Empty, options?: RpcOptions): UnaryCall<Empty, User> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, User>("unary", this._transport, method, opt, input);
    }
}
