import { refreshJWT } from '@/api/auth.ts'
import { ErrorCodeEnum } from '@/interfaces/error.ts'

export async function apiRequestWrapper<T>(apiCall: () => Promise<T>): Promise<T> {
  try {
    return await apiCall()
  } catch (error: any) {
    if (error.code === ErrorCodeEnum.PERMISSION_DENIED) {
      await refreshJWT()
      return await apiCall()
    } else {
      throw error
    }
  }
}
