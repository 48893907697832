// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "notifications_service.proto" (package "notifications_service", syntax proto3)
// tslint:disable
//
// Protobuf definition file
//
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message notifications_service.Empty
 */
export interface Empty {
}
/**
 * @generated from protobuf message notifications_service.Filters
 */
export interface Filters {
    /**
     * @generated from protobuf field: bool unreadOnly = 1;
     */
    unreadOnly: boolean;
}
/**
 * @generated from protobuf message notifications_service.NotificationInfo
 */
export interface NotificationInfo {
    /**
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * @generated from protobuf field: string content = 2;
     */
    content: string;
    /**
     * @generated from protobuf field: int64 timestamp = 3;
     */
    timestamp: bigint;
    /**
     * @generated from protobuf field: bool isRead = 4;
     */
    isRead: boolean;
}
/**
 * @generated from protobuf message notifications_service.Notifications
 */
export interface Notifications {
    /**
     * @generated from protobuf field: repeated int32 ids = 1;
     */
    ids: number[];
    /**
     * @generated from protobuf field: repeated notifications_service.NotificationInfo notifications = 2;
     */
    notifications: NotificationInfo[];
}
/**
 * @generated from protobuf message notifications_service.NotificationNumber
 */
export interface NotificationNumber {
    /**
     * @generated from protobuf field: int32 notificationNumber = 1;
     */
    notificationNumber: number;
}
/**
 * @generated from protobuf message notifications_service.Ids
 */
export interface Ids {
    /**
     * @generated from protobuf field: repeated int32 ids = 1;
     */
    ids: number[];
}
/**
 * @generated from protobuf message notifications_service.Subscription
 */
export interface Subscription {
    /**
     * @generated from protobuf field: notifications_service.HolderType holderType = 1;
     */
    holderType: HolderType;
    /**
     * @generated from protobuf field: int32 holderId = 2;
     */
    holderId: number;
}
/**
 * @generated from protobuf message notifications_service.IsSubscribedResponce
 */
export interface IsSubscribedResponce {
    /**
     * @generated from protobuf field: bool isSubscribed = 1;
     */
    isSubscribed: boolean;
}
/**
 * @generated from protobuf message notifications_service.Subscriptions
 */
export interface Subscriptions {
    /**
     * @generated from protobuf field: repeated notifications_service.Subscription subscriptions = 1;
     */
    subscriptions: Subscription[];
}
/**
 * @generated from protobuf message notifications_service.CreateNotificationRequest
 */
export interface CreateNotificationRequest {
    /**
     * @generated from protobuf field: notifications_service.HolderType holderType = 1;
     */
    holderType: HolderType;
    /**
     * @generated from protobuf field: int32 holderId = 2;
     */
    holderId: number;
    /**
     * @generated from protobuf field: string title = 3;
     */
    title: string;
    /**
     * @generated from protobuf field: string content = 4;
     */
    content: string;
}
/**
 * @generated from protobuf message notifications_service.SubscriptionRequest
 */
export interface SubscriptionRequest {
    /**
     * @generated from protobuf field: notifications_service.HolderType holderType = 1;
     */
    holderType: HolderType;
    /**
     * @generated from protobuf field: int32 holderId = 2;
     */
    holderId: number;
    /**
     * @generated from protobuf field: int32 userId = 3;
     */
    userId: number;
}
/**
 * @generated from protobuf enum notifications_service.HolderType
 */
export enum HolderType {
    /**
     * @generated from protobuf enum value: Course = 0;
     */
    Course = 0,
    /**
     * @generated from protobuf enum value: Organization = 1;
     */
    Organization = 1,
    /**
     * @generated from protobuf enum value: User = 2;
     */
    User = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class Empty$Type extends MessageType<Empty> {
    constructor() {
        super("notifications_service.Empty", []);
    }
    create(value?: PartialMessage<Empty>): Empty {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<Empty>(this, message, value);
        return message;
    }
    internalBinaryRead(target?: Empty): Empty {
        return target ?? this.create();
    }
    internalBinaryWrite(message: Empty, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications_service.Empty
 */
export const Empty = new Empty$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Filters$Type extends MessageType<Filters> {
    constructor() {
        super("notifications_service.Filters", [
            { no: 1, name: "unreadOnly", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Filters>): Filters {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.unreadOnly = false;
        if (value !== undefined)
            reflectionMergePartial<Filters>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Filters): Filters {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool unreadOnly */ 1:
                    message.unreadOnly = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Filters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool unreadOnly = 1; */
        if (message.unreadOnly !== false)
            writer.tag(1, WireType.Varint).bool(message.unreadOnly);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications_service.Filters
 */
export const Filters = new Filters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationInfo$Type extends MessageType<NotificationInfo> {
    constructor() {
        super("notifications_service.NotificationInfo", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "content", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "timestamp", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "isRead", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<NotificationInfo>): NotificationInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.title = "";
        message.content = "";
        message.timestamp = 0n;
        message.isRead = false;
        if (value !== undefined)
            reflectionMergePartial<NotificationInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NotificationInfo): NotificationInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* string content */ 2:
                    message.content = reader.string();
                    break;
                case /* int64 timestamp */ 3:
                    message.timestamp = reader.int64().toBigInt();
                    break;
                case /* bool isRead */ 4:
                    message.isRead = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NotificationInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* string content = 2; */
        if (message.content !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.content);
        /* int64 timestamp = 3; */
        if (message.timestamp !== 0n)
            writer.tag(3, WireType.Varint).int64(message.timestamp);
        /* bool isRead = 4; */
        if (message.isRead !== false)
            writer.tag(4, WireType.Varint).bool(message.isRead);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications_service.NotificationInfo
 */
export const NotificationInfo = new NotificationInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Notifications$Type extends MessageType<Notifications> {
    constructor() {
        super("notifications_service.Notifications", [
            { no: 1, name: "ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "notifications", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NotificationInfo }
        ]);
    }
    create(value?: PartialMessage<Notifications>): Notifications {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ids = [];
        message.notifications = [];
        if (value !== undefined)
            reflectionMergePartial<Notifications>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Notifications): Notifications {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int32 ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.ids.push(reader.int32());
                    else
                        message.ids.push(reader.int32());
                    break;
                case /* repeated notifications_service.NotificationInfo notifications */ 2:
                    message.notifications.push(NotificationInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Notifications, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int32 ids = 1; */
        if (message.ids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.ids.length; i++)
                writer.int32(message.ids[i]);
            writer.join();
        }
        /* repeated notifications_service.NotificationInfo notifications = 2; */
        for (let i = 0; i < message.notifications.length; i++)
            NotificationInfo.internalBinaryWrite(message.notifications[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications_service.Notifications
 */
export const Notifications = new Notifications$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationNumber$Type extends MessageType<NotificationNumber> {
    constructor() {
        super("notifications_service.NotificationNumber", [
            { no: 1, name: "notificationNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<NotificationNumber>): NotificationNumber {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.notificationNumber = 0;
        if (value !== undefined)
            reflectionMergePartial<NotificationNumber>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NotificationNumber): NotificationNumber {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 notificationNumber */ 1:
                    message.notificationNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NotificationNumber, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 notificationNumber = 1; */
        if (message.notificationNumber !== 0)
            writer.tag(1, WireType.Varint).int32(message.notificationNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications_service.NotificationNumber
 */
export const NotificationNumber = new NotificationNumber$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Ids$Type extends MessageType<Ids> {
    constructor() {
        super("notifications_service.Ids", [
            { no: 1, name: "ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Ids>): Ids {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ids = [];
        if (value !== undefined)
            reflectionMergePartial<Ids>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Ids): Ids {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int32 ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.ids.push(reader.int32());
                    else
                        message.ids.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Ids, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int32 ids = 1; */
        if (message.ids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.ids.length; i++)
                writer.int32(message.ids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications_service.Ids
 */
export const Ids = new Ids$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Subscription$Type extends MessageType<Subscription> {
    constructor() {
        super("notifications_service.Subscription", [
            { no: 1, name: "holderType", kind: "enum", T: () => ["notifications_service.HolderType", HolderType] },
            { no: 2, name: "holderId", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Subscription>): Subscription {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.holderType = 0;
        message.holderId = 0;
        if (value !== undefined)
            reflectionMergePartial<Subscription>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Subscription): Subscription {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* notifications_service.HolderType holderType */ 1:
                    message.holderType = reader.int32();
                    break;
                case /* int32 holderId */ 2:
                    message.holderId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Subscription, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* notifications_service.HolderType holderType = 1; */
        if (message.holderType !== 0)
            writer.tag(1, WireType.Varint).int32(message.holderType);
        /* int32 holderId = 2; */
        if (message.holderId !== 0)
            writer.tag(2, WireType.Varint).int32(message.holderId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications_service.Subscription
 */
export const Subscription = new Subscription$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IsSubscribedResponce$Type extends MessageType<IsSubscribedResponce> {
    constructor() {
        super("notifications_service.IsSubscribedResponce", [
            { no: 1, name: "isSubscribed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<IsSubscribedResponce>): IsSubscribedResponce {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.isSubscribed = false;
        if (value !== undefined)
            reflectionMergePartial<IsSubscribedResponce>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IsSubscribedResponce): IsSubscribedResponce {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool isSubscribed */ 1:
                    message.isSubscribed = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IsSubscribedResponce, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool isSubscribed = 1; */
        if (message.isSubscribed !== false)
            writer.tag(1, WireType.Varint).bool(message.isSubscribed);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications_service.IsSubscribedResponce
 */
export const IsSubscribedResponce = new IsSubscribedResponce$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Subscriptions$Type extends MessageType<Subscriptions> {
    constructor() {
        super("notifications_service.Subscriptions", [
            { no: 1, name: "subscriptions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Subscription }
        ]);
    }
    create(value?: PartialMessage<Subscriptions>): Subscriptions {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.subscriptions = [];
        if (value !== undefined)
            reflectionMergePartial<Subscriptions>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Subscriptions): Subscriptions {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated notifications_service.Subscription subscriptions */ 1:
                    message.subscriptions.push(Subscription.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Subscriptions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated notifications_service.Subscription subscriptions = 1; */
        for (let i = 0; i < message.subscriptions.length; i++)
            Subscription.internalBinaryWrite(message.subscriptions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications_service.Subscriptions
 */
export const Subscriptions = new Subscriptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateNotificationRequest$Type extends MessageType<CreateNotificationRequest> {
    constructor() {
        super("notifications_service.CreateNotificationRequest", [
            { no: 1, name: "holderType", kind: "enum", T: () => ["notifications_service.HolderType", HolderType] },
            { no: 2, name: "holderId", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "content", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateNotificationRequest>): CreateNotificationRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.holderType = 0;
        message.holderId = 0;
        message.title = "";
        message.content = "";
        if (value !== undefined)
            reflectionMergePartial<CreateNotificationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateNotificationRequest): CreateNotificationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* notifications_service.HolderType holderType */ 1:
                    message.holderType = reader.int32();
                    break;
                case /* int32 holderId */ 2:
                    message.holderId = reader.int32();
                    break;
                case /* string title */ 3:
                    message.title = reader.string();
                    break;
                case /* string content */ 4:
                    message.content = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateNotificationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* notifications_service.HolderType holderType = 1; */
        if (message.holderType !== 0)
            writer.tag(1, WireType.Varint).int32(message.holderType);
        /* int32 holderId = 2; */
        if (message.holderId !== 0)
            writer.tag(2, WireType.Varint).int32(message.holderId);
        /* string title = 3; */
        if (message.title !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.title);
        /* string content = 4; */
        if (message.content !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.content);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications_service.CreateNotificationRequest
 */
export const CreateNotificationRequest = new CreateNotificationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubscriptionRequest$Type extends MessageType<SubscriptionRequest> {
    constructor() {
        super("notifications_service.SubscriptionRequest", [
            { no: 1, name: "holderType", kind: "enum", T: () => ["notifications_service.HolderType", HolderType] },
            { no: 2, name: "holderId", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "userId", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SubscriptionRequest>): SubscriptionRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.holderType = 0;
        message.holderId = 0;
        message.userId = 0;
        if (value !== undefined)
            reflectionMergePartial<SubscriptionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SubscriptionRequest): SubscriptionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* notifications_service.HolderType holderType */ 1:
                    message.holderType = reader.int32();
                    break;
                case /* int32 holderId */ 2:
                    message.holderId = reader.int32();
                    break;
                case /* int32 userId */ 3:
                    message.userId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SubscriptionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* notifications_service.HolderType holderType = 1; */
        if (message.holderType !== 0)
            writer.tag(1, WireType.Varint).int32(message.holderType);
        /* int32 holderId = 2; */
        if (message.holderId !== 0)
            writer.tag(2, WireType.Varint).int32(message.holderId);
        /* int32 userId = 3; */
        if (message.userId !== 0)
            writer.tag(3, WireType.Varint).int32(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications_service.SubscriptionRequest
 */
export const SubscriptionRequest = new SubscriptionRequest$Type();
/**
 * @generated ServiceType for protobuf service notifications_service.NotificationsPublic
 */
export const NotificationsPublic = new ServiceType("notifications_service.NotificationsPublic", [
    { name: "GetNotifications", options: {}, I: Filters, O: Notifications },
    { name: "GetNotificationNumber", options: {}, I: Filters, O: NotificationNumber },
    { name: "MarkRead", options: {}, I: Ids, O: Empty },
    { name: "Subscribe", options: {}, I: Subscription, O: Empty },
    { name: "Unsubscribe", options: {}, I: Subscription, O: Empty },
    { name: "IsSubscribed", options: {}, I: Subscription, O: IsSubscribedResponce },
    { name: "GetSubscriptions", options: {}, I: Empty, O: Subscriptions }
]);
/**
 * @generated ServiceType for protobuf service notifications_service.NotificationsPrivate
 */
export const NotificationsPrivate = new ServiceType("notifications_service.NotificationsPrivate", [
    { name: "CreateNotification", options: {}, I: CreateNotificationRequest, O: Empty },
    { name: "RemoveAllHolderSubscriptions", options: {}, I: Subscription, O: Empty },
    { name: "RemoveSubscription", options: {}, I: SubscriptionRequest, O: Empty },
    { name: "AddSubscription", options: {}, I: SubscriptionRequest, O: Empty }
]);
