export default [
  {
    path: 'challenges/submissions',
    children: [
      {
        path: '',
        name: 'Challenges',
        component: () => import('@/views/challenges/CourseChallenges.vue'),
      },
      {
        path: '',
        component: () => import('@/views/challenges/components/ChallengeOverview.vue'),
        children: [
          {
            path: 'test/:submissionId',
            component: () => import('@/views/test/TestSubmissions.vue'),
            name: 'TestSubmissions',
          },
          {
            path: 'contest/:submissionId',
            component: () => import('@/views/contest/configuration/config-pages/Submissions.vue'),
            name: 'ContestSubmissions',
          },
        ],
      },
    ],
  },
]
