// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "courses_service.proto" (package "courses_service", syntax proto3)
// tslint:disable
//
// Protobuf definition file
//
import { CoursesPrivate } from "./courses_service";
import type { OrganizationId } from "./courses_service";
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Courses } from "./courses_service";
import type { UpdateSectionsOrderRequest } from "./courses_service";
import type { UpdateSectionInfoRequest } from "./courses_service";
import type { GetSectionInfoResponse } from "./courses_service";
import type { GetSectionInfosResponse } from "./courses_service";
import type { SectionId } from "./courses_service";
import type { SectionInfo } from "./courses_service";
import type { UserIds } from "./courses_service";
import type { DeclinedEmails } from "./courses_service";
import type { AddStudentsRequest } from "./courses_service";
import type { ManageUserRequest } from "./courses_service";
import type { GetAllCoursesPermissionsResponse } from "./courses_service";
import type { Permissions } from "./courses_service";
import type { IsPublicResponse } from "./courses_service";
import type { GetAllCoursesInfoResponse } from "./courses_service";
import type { GetAllCoursesInfoRequest } from "./courses_service";
import type { ExtendedCourseInfo } from "./courses_service";
import type { Empty } from "./courses_service";
import type { UpdateCourseInfoRequest } from "./courses_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { CourseId } from "./courses_service";
import type { CourseInfo } from "./courses_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * The service definition.
 *
 * @generated from protobuf service courses_service.Courses
 */
export interface ICoursesClient {
    /**
     * @generated from protobuf rpc: CreateCourse(courses_service.CourseInfo) returns (courses_service.CourseId);
     */
    createCourse(input: CourseInfo, options?: RpcOptions): UnaryCall<CourseInfo, CourseId>;
    /**
     * @generated from protobuf rpc: UpdateCourseInfo(courses_service.UpdateCourseInfoRequest) returns (courses_service.Empty);
     */
    updateCourseInfo(input: UpdateCourseInfoRequest, options?: RpcOptions): UnaryCall<UpdateCourseInfoRequest, Empty>;
    /**
     * @generated from protobuf rpc: GetCourseInfo(courses_service.CourseId) returns (courses_service.ExtendedCourseInfo);
     */
    getCourseInfo(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, ExtendedCourseInfo>;
    /**
     * @generated from protobuf rpc: GetAllCoursesInfo(courses_service.GetAllCoursesInfoRequest) returns (courses_service.GetAllCoursesInfoResponse);
     */
    getAllCoursesInfo(input: GetAllCoursesInfoRequest, options?: RpcOptions): UnaryCall<GetAllCoursesInfoRequest, GetAllCoursesInfoResponse>;
    /**
     * @generated from protobuf rpc: GetDeletedCoursesInfo(courses_service.GetAllCoursesInfoRequest) returns (courses_service.GetAllCoursesInfoResponse);
     */
    getDeletedCoursesInfo(input: GetAllCoursesInfoRequest, options?: RpcOptions): UnaryCall<GetAllCoursesInfoRequest, GetAllCoursesInfoResponse>;
    /**
     * @generated from protobuf rpc: DeleteCourse(courses_service.CourseId) returns (courses_service.Empty);
     */
    deleteCourse(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, Empty>;
    /**
     * @generated from protobuf rpc: RestoreCourse(courses_service.CourseId) returns (courses_service.Empty);
     */
    restoreCourse(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, Empty>;
    /**
     * @generated from protobuf rpc: IsPublic(courses_service.CourseId) returns (courses_service.IsPublicResponse);
     */
    isPublic(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, IsPublicResponse>;
    /**
     * @generated from protobuf rpc: GetCoursePermissions(courses_service.CourseId) returns (courses_service.Permissions);
     */
    getCoursePermissions(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, Permissions>;
    /**
     * @generated from protobuf rpc: GetAllCoursesPermissions(courses_service.GetAllCoursesInfoRequest) returns (courses_service.GetAllCoursesPermissionsResponse);
     */
    getAllCoursesPermissions(input: GetAllCoursesInfoRequest, options?: RpcOptions): UnaryCall<GetAllCoursesInfoRequest, GetAllCoursesPermissionsResponse>;
    /**
     * @generated from protobuf rpc: AddTeacher(courses_service.ManageUserRequest) returns (courses_service.Empty);
     */
    addTeacher(input: ManageUserRequest, options?: RpcOptions): UnaryCall<ManageUserRequest, Empty>;
    /**
     * @generated from protobuf rpc: AddStudent(courses_service.ManageUserRequest) returns (courses_service.Empty);
     */
    addStudent(input: ManageUserRequest, options?: RpcOptions): UnaryCall<ManageUserRequest, Empty>;
    /**
     * @generated from protobuf rpc: AddStudents(courses_service.AddStudentsRequest) returns (courses_service.DeclinedEmails);
     */
    addStudents(input: AddStudentsRequest, options?: RpcOptions): UnaryCall<AddStudentsRequest, DeclinedEmails>;
    /**
     * @generated from protobuf rpc: RemoveTeacher(courses_service.ManageUserRequest) returns (courses_service.Empty);
     */
    removeTeacher(input: ManageUserRequest, options?: RpcOptions): UnaryCall<ManageUserRequest, Empty>;
    /**
     * @generated from protobuf rpc: RemoveStudent(courses_service.ManageUserRequest) returns (courses_service.Empty);
     */
    removeStudent(input: ManageUserRequest, options?: RpcOptions): UnaryCall<ManageUserRequest, Empty>;
    /**
     * @generated from protobuf rpc: GetTeachers(courses_service.CourseId) returns (courses_service.UserIds);
     */
    getTeachers(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, UserIds>;
    /**
     * @generated from protobuf rpc: GetStudents(courses_service.CourseId) returns (courses_service.UserIds);
     */
    getStudents(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, UserIds>;
    /**
     * @generated from protobuf rpc: CreateSection(courses_service.SectionInfo) returns (courses_service.SectionId);
     */
    createSection(input: SectionInfo, options?: RpcOptions): UnaryCall<SectionInfo, SectionId>;
    /**
     * @generated from protobuf rpc: GetAllSectionsInfo(courses_service.CourseId) returns (courses_service.GetSectionInfosResponse);
     */
    getAllSectionsInfo(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, GetSectionInfosResponse>;
    /**
     * @generated from protobuf rpc: GetDeletedSectionsInfo(courses_service.CourseId) returns (courses_service.GetSectionInfosResponse);
     */
    getDeletedSectionsInfo(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, GetSectionInfosResponse>;
    /**
     * @generated from protobuf rpc: GetSectionInfo(courses_service.SectionId) returns (courses_service.GetSectionInfoResponse);
     */
    getSectionInfo(input: SectionId, options?: RpcOptions): UnaryCall<SectionId, GetSectionInfoResponse>;
    /**
     * @generated from protobuf rpc: UpdateSectionInfo(courses_service.UpdateSectionInfoRequest) returns (courses_service.Empty);
     */
    updateSectionInfo(input: UpdateSectionInfoRequest, options?: RpcOptions): UnaryCall<UpdateSectionInfoRequest, Empty>;
    /**
     * @generated from protobuf rpc: UpdateSectionsOrder(courses_service.UpdateSectionsOrderRequest) returns (courses_service.Empty);
     */
    updateSectionsOrder(input: UpdateSectionsOrderRequest, options?: RpcOptions): UnaryCall<UpdateSectionsOrderRequest, Empty>;
    /**
     * @generated from protobuf rpc: DeleteSection(courses_service.SectionId) returns (courses_service.Empty);
     */
    deleteSection(input: SectionId, options?: RpcOptions): UnaryCall<SectionId, Empty>;
    /**
     * @generated from protobuf rpc: RestoreSection(courses_service.SectionId) returns (courses_service.Empty);
     */
    restoreSection(input: SectionId, options?: RpcOptions): UnaryCall<SectionId, Empty>;
    /**
     * Deprecated
     *
     * @generated from protobuf rpc: GetSectionInfos(courses_service.CourseId) returns (courses_service.GetSectionInfosResponse);
     */
    getSectionInfos(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, GetSectionInfosResponse>;
}
/**
 * The service definition.
 *
 * @generated from protobuf service courses_service.Courses
 */
export class CoursesClient implements ICoursesClient, ServiceInfo {
    typeName = Courses.typeName;
    methods = Courses.methods;
    options = Courses.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: CreateCourse(courses_service.CourseInfo) returns (courses_service.CourseId);
     */
    createCourse(input: CourseInfo, options?: RpcOptions): UnaryCall<CourseInfo, CourseId> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CourseInfo, CourseId>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCourseInfo(courses_service.UpdateCourseInfoRequest) returns (courses_service.Empty);
     */
    updateCourseInfo(input: UpdateCourseInfoRequest, options?: RpcOptions): UnaryCall<UpdateCourseInfoRequest, Empty> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCourseInfoRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCourseInfo(courses_service.CourseId) returns (courses_service.ExtendedCourseInfo);
     */
    getCourseInfo(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, ExtendedCourseInfo> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<CourseId, ExtendedCourseInfo>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAllCoursesInfo(courses_service.GetAllCoursesInfoRequest) returns (courses_service.GetAllCoursesInfoResponse);
     */
    getAllCoursesInfo(input: GetAllCoursesInfoRequest, options?: RpcOptions): UnaryCall<GetAllCoursesInfoRequest, GetAllCoursesInfoResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAllCoursesInfoRequest, GetAllCoursesInfoResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetDeletedCoursesInfo(courses_service.GetAllCoursesInfoRequest) returns (courses_service.GetAllCoursesInfoResponse);
     */
    getDeletedCoursesInfo(input: GetAllCoursesInfoRequest, options?: RpcOptions): UnaryCall<GetAllCoursesInfoRequest, GetAllCoursesInfoResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAllCoursesInfoRequest, GetAllCoursesInfoResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteCourse(courses_service.CourseId) returns (courses_service.Empty);
     */
    deleteCourse(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, Empty> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<CourseId, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RestoreCourse(courses_service.CourseId) returns (courses_service.Empty);
     */
    restoreCourse(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, Empty> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<CourseId, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: IsPublic(courses_service.CourseId) returns (courses_service.IsPublicResponse);
     */
    isPublic(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, IsPublicResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<CourseId, IsPublicResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCoursePermissions(courses_service.CourseId) returns (courses_service.Permissions);
     */
    getCoursePermissions(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, Permissions> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<CourseId, Permissions>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAllCoursesPermissions(courses_service.GetAllCoursesInfoRequest) returns (courses_service.GetAllCoursesPermissionsResponse);
     */
    getAllCoursesPermissions(input: GetAllCoursesInfoRequest, options?: RpcOptions): UnaryCall<GetAllCoursesInfoRequest, GetAllCoursesPermissionsResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAllCoursesInfoRequest, GetAllCoursesPermissionsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddTeacher(courses_service.ManageUserRequest) returns (courses_service.Empty);
     */
    addTeacher(input: ManageUserRequest, options?: RpcOptions): UnaryCall<ManageUserRequest, Empty> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<ManageUserRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddStudent(courses_service.ManageUserRequest) returns (courses_service.Empty);
     */
    addStudent(input: ManageUserRequest, options?: RpcOptions): UnaryCall<ManageUserRequest, Empty> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<ManageUserRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddStudents(courses_service.AddStudentsRequest) returns (courses_service.DeclinedEmails);
     */
    addStudents(input: AddStudentsRequest, options?: RpcOptions): UnaryCall<AddStudentsRequest, DeclinedEmails> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddStudentsRequest, DeclinedEmails>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveTeacher(courses_service.ManageUserRequest) returns (courses_service.Empty);
     */
    removeTeacher(input: ManageUserRequest, options?: RpcOptions): UnaryCall<ManageUserRequest, Empty> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<ManageUserRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveStudent(courses_service.ManageUserRequest) returns (courses_service.Empty);
     */
    removeStudent(input: ManageUserRequest, options?: RpcOptions): UnaryCall<ManageUserRequest, Empty> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<ManageUserRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTeachers(courses_service.CourseId) returns (courses_service.UserIds);
     */
    getTeachers(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, UserIds> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<CourseId, UserIds>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetStudents(courses_service.CourseId) returns (courses_service.UserIds);
     */
    getStudents(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, UserIds> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<CourseId, UserIds>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateSection(courses_service.SectionInfo) returns (courses_service.SectionId);
     */
    createSection(input: SectionInfo, options?: RpcOptions): UnaryCall<SectionInfo, SectionId> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<SectionInfo, SectionId>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAllSectionsInfo(courses_service.CourseId) returns (courses_service.GetSectionInfosResponse);
     */
    getAllSectionsInfo(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, GetSectionInfosResponse> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<CourseId, GetSectionInfosResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetDeletedSectionsInfo(courses_service.CourseId) returns (courses_service.GetSectionInfosResponse);
     */
    getDeletedSectionsInfo(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, GetSectionInfosResponse> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<CourseId, GetSectionInfosResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSectionInfo(courses_service.SectionId) returns (courses_service.GetSectionInfoResponse);
     */
    getSectionInfo(input: SectionId, options?: RpcOptions): UnaryCall<SectionId, GetSectionInfoResponse> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<SectionId, GetSectionInfoResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateSectionInfo(courses_service.UpdateSectionInfoRequest) returns (courses_service.Empty);
     */
    updateSectionInfo(input: UpdateSectionInfoRequest, options?: RpcOptions): UnaryCall<UpdateSectionInfoRequest, Empty> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateSectionInfoRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateSectionsOrder(courses_service.UpdateSectionsOrderRequest) returns (courses_service.Empty);
     */
    updateSectionsOrder(input: UpdateSectionsOrderRequest, options?: RpcOptions): UnaryCall<UpdateSectionsOrderRequest, Empty> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateSectionsOrderRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteSection(courses_service.SectionId) returns (courses_service.Empty);
     */
    deleteSection(input: SectionId, options?: RpcOptions): UnaryCall<SectionId, Empty> {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept<SectionId, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RestoreSection(courses_service.SectionId) returns (courses_service.Empty);
     */
    restoreSection(input: SectionId, options?: RpcOptions): UnaryCall<SectionId, Empty> {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept<SectionId, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * Deprecated
     *
     * @generated from protobuf rpc: GetSectionInfos(courses_service.CourseId) returns (courses_service.GetSectionInfosResponse);
     */
    getSectionInfos(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, GetSectionInfosResponse> {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept<CourseId, GetSectionInfosResponse>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service courses_service.CoursesPrivate
 */
export interface ICoursesPrivateClient {
    /**
     * @generated from protobuf rpc: GetCourseOrganization(courses_service.CourseId) returns (courses_service.OrganizationId);
     */
    getCourseOrganization(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, OrganizationId>;
    /**
     * @generated from protobuf rpc: RestoreCourse(courses_service.CourseId) returns (courses_service.Empty);
     */
    restoreCourse(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, Empty>;
}
/**
 * @generated from protobuf service courses_service.CoursesPrivate
 */
export class CoursesPrivateClient implements ICoursesPrivateClient, ServiceInfo {
    typeName = CoursesPrivate.typeName;
    methods = CoursesPrivate.methods;
    options = CoursesPrivate.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetCourseOrganization(courses_service.CourseId) returns (courses_service.OrganizationId);
     */
    getCourseOrganization(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, OrganizationId> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CourseId, OrganizationId>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RestoreCourse(courses_service.CourseId) returns (courses_service.Empty);
     */
    restoreCourse(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, Empty> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<CourseId, Empty>("unary", this._transport, method, opt, input);
    }
}
