// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "auth_service.proto" (package "auth_service", syntax proto3)
// tslint:disable
import { AuthPrivate } from "./auth_service";
import type { UserId } from "./auth_service";
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Auth } from "./auth_service";
import type { GoogleAuthRequest } from "./auth_service";
import type { RefreshJWTResponse } from "./auth_service";
import type { RefreshJWTRequest } from "./auth_service";
import type { GetNewSecretQRResponse } from "./auth_service";
import type { GetNewSecretQRRequest } from "./auth_service";
import type { LoginAvailability } from "./auth_service";
import type { CheckLoginAvailabilityRequest } from "./auth_service";
import type { AuthTokens } from "./auth_service";
import type { ValidatePasscodeRequest } from "./auth_service";
import type { Empty } from "./auth_service";
import type { SendEmailPasscodeRequest } from "./auth_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { AccountInfoResponse } from "./auth_service";
import type { AccountInfoRequest } from "./auth_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * ———————————————————— Auth Service ————————————————————
 *
 * @generated from protobuf service auth_service.Auth
 */
export interface IAuthClient {
    /**
     * OTP
     *
     * @generated from protobuf rpc: GetAccountInfo(auth_service.AccountInfoRequest) returns (auth_service.AccountInfoResponse);
     */
    getAccountInfo(input: AccountInfoRequest, options?: RpcOptions): UnaryCall<AccountInfoRequest, AccountInfoResponse>;
    /**
     * @generated from protobuf rpc: SendEmailPasscode(auth_service.SendEmailPasscodeRequest) returns (auth_service.Empty);
     */
    sendEmailPasscode(input: SendEmailPasscodeRequest, options?: RpcOptions): UnaryCall<SendEmailPasscodeRequest, Empty>;
    /**
     * @generated from protobuf rpc: ValidatePasscode(auth_service.ValidatePasscodeRequest) returns (auth_service.AuthTokens);
     */
    validatePasscode(input: ValidatePasscodeRequest, options?: RpcOptions): UnaryCall<ValidatePasscodeRequest, AuthTokens>;
    /**
     * @generated from protobuf rpc: CheckLoginAvailability(auth_service.CheckLoginAvailabilityRequest) returns (auth_service.LoginAvailability);
     */
    checkLoginAvailability(input: CheckLoginAvailabilityRequest, options?: RpcOptions): UnaryCall<CheckLoginAvailabilityRequest, LoginAvailability>;
    /**
     * @generated from protobuf rpc: GetNewSecretQR(auth_service.GetNewSecretQRRequest) returns (auth_service.GetNewSecretQRResponse);
     */
    getNewSecretQR(input: GetNewSecretQRRequest, options?: RpcOptions): UnaryCall<GetNewSecretQRRequest, GetNewSecretQRResponse>;
    /**
     * JWT
     *
     * @generated from protobuf rpc: RefreshJWT(auth_service.RefreshJWTRequest) returns (auth_service.RefreshJWTResponse);
     */
    refreshJWT(input: RefreshJWTRequest, options?: RpcOptions): UnaryCall<RefreshJWTRequest, RefreshJWTResponse>;
    /**
     * Google Auth
     *
     * @generated from protobuf rpc: AuthWithGoogle(auth_service.GoogleAuthRequest) returns (auth_service.AuthTokens);
     */
    authWithGoogle(input: GoogleAuthRequest, options?: RpcOptions): UnaryCall<GoogleAuthRequest, AuthTokens>;
}
/**
 * ———————————————————— Auth Service ————————————————————
 *
 * @generated from protobuf service auth_service.Auth
 */
export class AuthClient implements IAuthClient, ServiceInfo {
    typeName = Auth.typeName;
    methods = Auth.methods;
    options = Auth.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * OTP
     *
     * @generated from protobuf rpc: GetAccountInfo(auth_service.AccountInfoRequest) returns (auth_service.AccountInfoResponse);
     */
    getAccountInfo(input: AccountInfoRequest, options?: RpcOptions): UnaryCall<AccountInfoRequest, AccountInfoResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<AccountInfoRequest, AccountInfoResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SendEmailPasscode(auth_service.SendEmailPasscodeRequest) returns (auth_service.Empty);
     */
    sendEmailPasscode(input: SendEmailPasscodeRequest, options?: RpcOptions): UnaryCall<SendEmailPasscodeRequest, Empty> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<SendEmailPasscodeRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ValidatePasscode(auth_service.ValidatePasscodeRequest) returns (auth_service.AuthTokens);
     */
    validatePasscode(input: ValidatePasscodeRequest, options?: RpcOptions): UnaryCall<ValidatePasscodeRequest, AuthTokens> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ValidatePasscodeRequest, AuthTokens>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CheckLoginAvailability(auth_service.CheckLoginAvailabilityRequest) returns (auth_service.LoginAvailability);
     */
    checkLoginAvailability(input: CheckLoginAvailabilityRequest, options?: RpcOptions): UnaryCall<CheckLoginAvailabilityRequest, LoginAvailability> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<CheckLoginAvailabilityRequest, LoginAvailability>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetNewSecretQR(auth_service.GetNewSecretQRRequest) returns (auth_service.GetNewSecretQRResponse);
     */
    getNewSecretQR(input: GetNewSecretQRRequest, options?: RpcOptions): UnaryCall<GetNewSecretQRRequest, GetNewSecretQRResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetNewSecretQRRequest, GetNewSecretQRResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * JWT
     *
     * @generated from protobuf rpc: RefreshJWT(auth_service.RefreshJWTRequest) returns (auth_service.RefreshJWTResponse);
     */
    refreshJWT(input: RefreshJWTRequest, options?: RpcOptions): UnaryCall<RefreshJWTRequest, RefreshJWTResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<RefreshJWTRequest, RefreshJWTResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Google Auth
     *
     * @generated from protobuf rpc: AuthWithGoogle(auth_service.GoogleAuthRequest) returns (auth_service.AuthTokens);
     */
    authWithGoogle(input: GoogleAuthRequest, options?: RpcOptions): UnaryCall<GoogleAuthRequest, AuthTokens> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GoogleAuthRequest, AuthTokens>("unary", this._transport, method, opt, input);
    }
}
/**
 * ———————————————————— Auth Private Service ————————————————————
 *
 * @generated from protobuf service auth_service.AuthPrivate
 */
export interface IAuthPrivateClient {
    /**
     * @generated from protobuf rpc: CreateAccount(auth_service.AccountInfoRequest) returns (auth_service.UserId);
     */
    createAccount(input: AccountInfoRequest, options?: RpcOptions): UnaryCall<AccountInfoRequest, UserId>;
    /**
     * @generated from protobuf rpc: RemoveUserKeyIfExists(auth_service.UserId) returns (auth_service.Empty);
     */
    removeUserKeyIfExists(input: UserId, options?: RpcOptions): UnaryCall<UserId, Empty>;
}
/**
 * ———————————————————— Auth Private Service ————————————————————
 *
 * @generated from protobuf service auth_service.AuthPrivate
 */
export class AuthPrivateClient implements IAuthPrivateClient, ServiceInfo {
    typeName = AuthPrivate.typeName;
    methods = AuthPrivate.methods;
    options = AuthPrivate.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: CreateAccount(auth_service.AccountInfoRequest) returns (auth_service.UserId);
     */
    createAccount(input: AccountInfoRequest, options?: RpcOptions): UnaryCall<AccountInfoRequest, UserId> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<AccountInfoRequest, UserId>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveUserKeyIfExists(auth_service.UserId) returns (auth_service.Empty);
     */
    removeUserKeyIfExists(input: UserId, options?: RpcOptions): UnaryCall<UserId, Empty> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<UserId, Empty>("unary", this._transport, method, opt, input);
    }
}
