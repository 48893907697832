import { onScopeDispose, ref, watch } from 'vue'
import { debounce } from 'lodash'
export function useLocalStorage<T>(key: string, defaultValue?: T) {
  const val = ref(defaultValue)

  const storageValue = localStorage.getItem(key)

  if (storageValue) {
    val.value = JSON.parse(storageValue)
  }

  function handleStorageEvent(event: StorageEvent) {
    if (event.key === key) {
      val.value = JSON.parse(event.newValue || 'null')
    }
  }

  window.addEventListener('storage', handleStorageEvent)

  onScopeDispose(() => window.removeEventListener('storage', handleStorageEvent))

  const debouncedUpdate = debounce((newValue) => {
    localStorage.setItem(key, JSON.stringify(newValue))
  }, 300)
  watch(val, (newValue) => debouncedUpdate(newValue), { deep: true })

  return val
}

export function clearAuthTokens() {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('email')
  localStorage.removeItem('assessmentsStatus')
  localStorage.removeItem('sectionsStatus')
}
